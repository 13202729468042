import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Navbar from '../../Component/Navbar/Navbar'
import TextField from '@mui/material/TextField';
import logo1 from '../../img/teeth.png'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Link } from "react-router-dom";
import { useState } from 'react';
import "../../Component/Create/create.css";
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
// import teeth from "../../img/teeth-image.jpg";
import Teeth from "../teeth/Teeth";
const diagnosisList = [
    {
        id:1,
        text: '',
    }
];
const initDiagnosisList = [
    {
        id:1,
        text: '',    
    }
];
export const PatientInfo = () => {

    const [diagnosisList, setList] = React.useState(initDiagnosisList);
    const [hidetboxTend, setHideboxTend] = useState(true);
    const [hidetboxClicking,setHideboxClicking] = useState(true);
    const [hidetboxJaw,setHideboxJaw] = useState(true);
    const [hidetboxSub,setHideboxSub] = useState(true);
    function handleChangeTend() {
        setHideboxTend(!hidetboxTend)
        console.log(hidetboxTend)
    }
    function handleChangeClicking() {
        setHideboxClicking(!hidetboxClicking)
    }
    function handleChangeJaw(){
        setHideboxJaw(!hidetboxJaw)
    }
    function handleChangeSub(){
        setHideboxSub(!hidetboxSub)
    }
    function handleChangeDiagnosis(){

    }
    function handleAddDiagnosis(){
        const newList = diagnosisList.concat({text:'', id: diagnosisList.length +1});
        setList(newList);
    }
    function remove(id){
        const newList = diagnosisList.filter((item)=> item.id !== id);
        setList(newList);
    }
    const [isAdding, setIsAdding] = useState('0');
    return (
        <div>
            <Navbar />
            <br>
            </br>
            {isAdding === '0' && (<div> <div className='row2Items' >
                <div className='itemRow'>
                    <div className=''>
                        <label>Cheif Complaint</label><br/>
                        <TextField id="filled-multiline-static"
                            label="write details"
                            multiline inputProps={{ style: { fontFamily: 'nunito', color: 'red' } }}
                            rows={4} className='twoTextField'
                        /></div>
                </div>

                <div className='itemRow'>
                    <div className=''>
                        <label>History of Chief Complaint</label><br/>
                        <TextField id="filled-multiline-static"
                            label="write details"
                            multiline
                            rows={4} className='twoTextField' /></div>
                </div>

            </div>
                <br></br>
                <div >
                    <div className='singleItem'>
                        <label>Medical History</label>
                        <TextField id="filled-multiline-static"
                            label="write details"
                            multiline
                            rows={4} className="singleTextField" />
                    </div>
                </div>
                <br></br>
                <div>
                    <div className='singleItem'>
                        <label>Dental History</label>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4} className='singleTextField' />
                    </div>


                </div>
                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <label>Drug History</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4} className='twoTextField' />
                    </div>
                    <div className='itemRow'>
                        <label>Allergic History</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4} className='twoTextField' />
                    </div>



                </div>
                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <label>Social History</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4} className='twoTextField'
                        />
                    </div>
                    <div className='itemRow'>
                        <label>Family History</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            multiline
                            rows={4} className='twoTextField' />
                    </div>

                </div>
                <br>
                </br>
                <div className='Center1'>
                    <h2 className='Text'>
                        Extra-Oral Examinatiom
                    </h2>
                </div>

                <br>
                </br>
                <div className='row3Items'>
                    <div className='itemRow3Items'>
                        <label>Face Symmetry</label>
                        <TextField id="filled-multiline-static"
                            label="DiscolourationNormal"
                            
                        />
                    </div>
                    <div className='itemRow3Items'>
                        <label>Scaring</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            
                        />
                    </div>
                    <div className='itemRow3Items'>
                        <label>Othert</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                        />
                    </div>

                </div>
                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <label>Skin Color</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            className='twoTextField'
                        />
                    </div>
                    <div className='itemRow'>
                        <label>Skin Lesions</label><br/>
                        <TextField id="filled-multiline-static"
                            label="Multiline"
                            className='twoTextField' />
                    </div>

                </div>
                <br>
                </br>

                <div className='Center1'>
                    <h2 className='Text'>
                        CMA (TMJ) Health
                    </h2>
                </div>

                <br>
                </br>

                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Tenderness
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleChangeTend}
                        >
                            <FormControlLabel value="Present" control={<Radio />} label="Present" />
                            <FormControlLabel value="Absent" control={<Radio />} label="Absent" />

                        </RadioGroup>
                    </FormControl></div>


                    <div className={`${hidetboxTend ? " TextField3" : "hidebox"}`} >  <TextField id="filled-multiline-static"
                        label="Multiline"

                    /></div>
                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Clicking
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleChangeClicking}
                        >
                            <FormControlLabel value="Present" control={<Radio />} label="Present" />
                            <FormControlLabel value="Absent" control={<Radio />} label="Absent" />

                        </RadioGroup>
                    </FormControl></div>


                    <div className={`${hidetboxClicking ? " TextField3" : "hidebox"}`} >  <TextField id="filled-multiline-static"
                        label="Multiline"

                    /></div>
                </div>
                <br>
                </br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Jaw rotation
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleChangeJaw}
                        >
                            <FormControlLabel value="Present" control={<Radio />} label="Present" />
                            <FormControlLabel value="Absent" control={<Radio />} label="Absent" />

                        </RadioGroup>
                    </FormControl></div>


                    <div className={`${hidetboxJaw ? " TextField3" : "hidebox"}`} >  <TextField id="filled-multiline-static"
                        label="Multiline"

                    /></div>
                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Subluxation
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={handleChangeSub}
                        >
                            <FormControlLabel value="Present" control={<Radio />} label="Present" />
                            <FormControlLabel value="Absent" control={<Radio />} label="Absent" />

                        </RadioGroup>
                    </FormControl></div>


                    <div className={`${hidetboxSub ? " TextField3" : "hidebox"}`} >  <TextField id="filled-multiline-static"
                        label="Multiline"

                    /></div>
                </div>
                <br>
                </br>

                <div className='Center1'>
                    <h2 className='Text'>
                        Lymph Nodes
                    </h2>
                </div>

                <br>
                </br>

                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Submandibular nodes
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Submental nodes
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Buccal and pre-auricular
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Post-auricular nodes
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Anterior superficial & deep cervical nodes
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Posterior superficial & deep cervical nodes
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>
                <div className='Center3'>
                    <div className="m-4">  <h2  >
                        Superaclavicular nodes
                    </h2></div>
                    <div className="m-4">  <FormControl>

                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"

                        >
                            <FormControlLabel value="not palpable" control={<Radio />} label="not palpable" />
                            <FormControlLabel value="palpable" control={<Radio />} label="palpable" />

                        </RadioGroup>
                    </FormControl></div>


                </div>
                <br></br>

                <div className='Center1'>
                    <button style={{textAlign: "center"}} className="Active" onClick={() => setIsAdding('1')} >Next</button><br></br>
                </div>


            </div>)}

            {isAdding === '1' && (<> <div className='Center1'>
                <h2 className='Text'>
                    Oral Mucosa
                </h2>
            </div>

                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Normal"
                            className='twoTextField'
                            
                        />
                    </div>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Discolouration"
                            className='towTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                    </div>

                </div>
                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Swelling"
                            className='twoTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }}
                        />
                    </div>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Ulcers"
                            className='twoTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                    </div>

                </div>
                <br></br><br></br>
                <div className='Center1'>
                    <h2 className='Text'>
                        Oral Hygiene Index-Simplified
                    </h2>

                </div>
                <br>
                </br>

                <div className="Center5">
                    <div id="container">
                        <div id="label">Plaque</div>
                        <br>
                        </br>
                        <div className="space-around">
                             <p>
                                16
                             </p>
                             <p>
                                11     </p>
                             <p>
                                26
                             </p>
                        </div>
                        <br>
                        </br><div className="space-around">
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>

                        </div>
                        <br>
                        </br><div className="space-around">
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>

                        </div>
                        <br></br>
                        <div className="space-around">
                             <p>
                                46
                             </p>
                             <p>
                                31    </p>
                             <p>
                                36
                             </p>
                        </div>
                    </div>

                    <div className="">
                        <img src={logo1} alt="" className='Logo-img' />
                    </div>

                </div>
                <div className="Center5">
                    <div id="container">
                        <div id="label">Calculus</div>
                        <br>
                        </br>
                        <div className="space-around">
                             <p>
                                16
                             </p>
                             <p>
                                11     </p>
                             <p>
                                26
                             </p>
                        </div>
                        <br>
                        </br><div className="space-around">
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>

                        </div>
                        <br>
                        </br><div className="space-around">
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>
                            <input className='input-space-around'>
                            </input>

                        </div>
                        <br></br>
                        <div className="space-around">
                             <p>
                                46
                             </p>
                             <p>
                                31    </p>
                             <p>
                                36
                             </p>
                        </div>
                    </div>

                    <div className="">

                        <div className="pink">

                            <h1>
                                Plaque = 0
                            </h1>


                        </div>
                        <div className="pink">

                            <h1>
                                Calculus = 0
                            </h1>


                        </div>
                        <div className="pinkEBEFFF">

                            <h1>
                                OHI-S = 1
                            </h1>


                        </div>
                    </div>

                </div>
                <br>
                </br>
                <div className="Center1">
                    <div className="pinkEBEFFF_space-around">
                        <h1>
                            Poor(0-1.2)
                        </h1>
                        <h1>
                            Fair(0-3.3)   </h1>
                        <h1>
                            Good(3.16)
                        </h1>
                    </div>
                </div>
                <br>
                </br>
                <div style={{backgroundColor:"#344AB9",borderRadius:"20px",padding:"30px",margin:"50px",justifyContent:"center",display:"flex"}}>
                    <Teeth/>
                </div>
                <div className='Center3'>
                    <button className="Active" style={{textAlign:"center"}} onClick={() => setIsAdding('2')} >Next</button><br></br>
                </div></>)}
            {isAdding === '2' && (<> <div className='Center1'>
            </div>
                <div className='singleItem'>
                    
                        <TextField id="filled-multiline-static"
                            label="Differential Diagnosis of Chief Complaint"
                            multiline
                            rows={3} className='singleTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                    


                </div>
                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Investigations"
                            className='twoTextField'
                            multiline
                            rows={4}
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }}
                        />
                    </div>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Radiographic Evaluation"
                            className='twoTextField'
                            multiline
                            rows={4}
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                    </div>

                </div>
                <br></br> <div className='singleItem'>
                    <TextField id="filled-multiline-static"
                        label="Consulation/ Referral"
                        multiline
                        rows={3} className='singleTextField'
                        sx={{
                            "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                            "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#4666D1" },
                            },
                        }} />
                </div>
                <br></br> <div className='Center1'>
                    <h2 className='Text'>
                        Final Diagnosis
                    </h2>
                    <br></br><br/>
                </div>
                
                {diagnosisList.map((item)=> (
                    <div className='normalRow' style={{margin: "20px 0"}}>
                    <h2 className='Text'>
                        {item.id}
                    </h2>
                        <input className='itemRow' id={item.id} text={item.text}>
                        </input>
                        <div style={{ display:"flex",justifyContent:"center"}}>
                        <RemoveCircleRoundedIcon style={{color: "red", width: "50px",height:"30px", cursor:"pointer"}} onClick={() => remove(item.id)}/>

                        </div>
                    </div>
                ))}
                <br></br>
                {/* <div className='normalRow'>
                    <h2 className='Text'>
                        2
                    </h2>

                    <input className='itemRow'>
                    </input>
                </div>
                <br></br> */}
                <div className='normalRow'>
                    <button className="Active1" style={{borderRadius:"10px",height:"40px",padding:'0px'}} onClick={handleAddDiagnosis} >
                        +</button><br></br>
                </div>
                <br></br>
                <div className='Center1'>

                    <h2 className='Text'>
                        Treatment Plan
                    </h2>

                </div>
                <br></br>
                <div className='singleItem'>          
                        <TextField id="filled-multiline-static"
                            label="Behavior Management method"
                            multiline
                            rows={1} className='singleTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                </div>
                <br>
                </br>
                <div className='singleItem'>
                        <TextField id="filled-multiline-static"
                            label="Emergency Phase"
                            multiline
                            rows={2} className='singleTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                </div>
                <br>
                </br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Phase I"
                            className='twoTextField'
                            rows={3}
                            multiline
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }}
                        />
                    </div>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Phase II (Surgical Phase)"
                            className='twoTextField'
                            rows={3}
                            multiline
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                    </div>

                </div>
                <br></br>
                <div className='row2Items'>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Phase III (Restorative Phase)"
                            className='twoTextField'
                            rows={3}
                            multiline
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }}
                        />
                    </div>
                    <div className='itemRow'>
                        <TextField id="filled-multiline-static"
                            label="Phase IV (Maintainence Phase)"
                            className='twoTextField'
                            rows={3}
                            multiline
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                    </div>

                </div>
                <br>
                </br>
                <div className='singleItem'>            
                        <TextField id="filled-multiline-static"
                            label="Recall (next appointment)"
                            multiline
                            rows={2} className='singleTextField'
                            sx={{
                                "& .MuiInputLabel-root": { color: '#4666D1' },//styles the label
                                "& .MuiOutlinedInput-root": {
                                    "& > fieldset": { borderColor: "#4666D1" },
                                },
                            }} />
                </div>
                <br>
                </br>

                <div className='Center3'>
                    <Link to={'/Doctor/Procedures'}><button className="Active1" style={{borderRadius: "20px"}}>
                        Done</button><br></br>
                    </Link>
                </div>
                <br></br>
            </>)}


        </div>
    )
}
