import React, { useState } from 'react'
import './Home.css'
import Navbar from '../Component/Navbar/Navbar';

import './Salder1.css';
import logo from "../img/icon1.png"
import SessionTable from '../Component/Table/Table' ;
import {Create as AddSession} from '../Component/Create/Create' ;
import  UsersControl from "./Users";
import RoomsList from "./Rooms";
import ServiceList from "./Services";
import Insurance from "./Insurance";
const Home = () => {
  const [isSelected, setIsSelected] = useState( localStorage.getItem('isSelected') );

  const patient = [{ Name: 'اسم المريض', Action: 'AddCircleIcon' }];
  const Doctor = [{ Name: 'اسم الدركتور', Action: 'ArrowDropDownCircleIcon' }];
  const Note = ['ملاحظات'];
  const Action = ['حفظ'];
  function FIsSelected(params) {
    console.log('a')
    localStorage.setItem('isSelected',params) 
     setIsSelected(params)
  }
  return (
    <div className='Home'>

      <div className='flex' style={{ backgroundColor: "#D8DBDE" }}>
        <div className='Table-center' style={{ justifyContent: "none" }}>
          <Navbar />
          {isSelected === "AddSession" && (
            <AddSession patient={patient} Note={Note} Action={Action} Doctor={Doctor}/>
          )}
          {isSelected === "PatientList" && (
            <SessionTable patient={patient} Note={Note} Action={Action} Doctor={Doctor} />
          )}
          {isSelected === "ServiceList" && (
            <ServiceList patient={patient} Note={Note} Action={Action} Doctor={Doctor} />
          )}
          {isSelected === "RoomList" && (
            <RoomsList patient={patient} Note={Note} Action={Action} Doctor={Doctor} />
          )}
          {isSelected === "UsersControl" && (
            <UsersControl patient={patient} Note={Note} Action={Action} Doctor={Doctor} />
          )}
          {isSelected === "Insurance" && (
            <Insurance patient={patient} Note={Note} Action={Action} Doctor={Doctor} />
          )}
        </div>



        <div className='slader'>
          <div className='img'>
            <img src={logo} alt="" className='Logo-img1' />
          </div>
          <br></br>
          <div className="buttonitems">
            <button className={`${isSelected === "AddSession" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("AddSession")} >إضافة جلسة</button><br></br>
            <button className={`${isSelected === "PatientList" ? "Active" : "NONActive"}`} onClick={() => FIsSelected( "PatientList")}  >قائمة المرضي</button><br></br>
            <button className={`${isSelected === "ServiceList" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("ServiceList")}  >قائمة الخدمات</button>
            <button className={`${isSelected === "RoomList" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("RoomList")}  >قائمة الغرف</button>
            <button className={`${isSelected === "UsersControl" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("UsersControl")}  >تحكم بالمستخدمين </button>
            <button className={`${isSelected === "Insurance" ? "Active" : "NONActive"}`} onClick={() => FIsSelected("Insurance")}  >التأمين</button>
          </div>
        </div>

      </div>




    </div>
  )
}

export default Home