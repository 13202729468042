import { Button } from '@mui/material';
import React from 'react'
import Navbar from '../../Component/Navbar/Navbar'
import Table from './Table';
import '../patient/Doctor_Patient_info.css'
export const Procedures = () => {
  return (
    <div>
      <Navbar />
      <br></br>

      <div style={{padding: "20px"}}>


        <div className=''>
          <h2 className='Text'>
             Add Procedures
          </h2>
        </div>
        <div className=''>
          <input className='input-space'>
          </input>

        <Button variant="contained" style={{padding:"10 30px", width: "50px", margin:"0 20px"}}>Save</Button>
        </div> 
        </div>

      <div className="">

        <Table />

      </div>



    </div>
  )
}
