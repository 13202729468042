import React , { useEffect,useState } from 'react'
import './patient/Doctor_Patient_info.css'
import { Link } from "react-router-dom";
import { Header } from '../Component/Header/Header';
import logo1 from '../img/dental-checkup.png'
import logo2 from '../img/news.png'
export const Doctor = () => {
  const [user, setUser] = useState()
  
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {

      const foundUser = JSON.parse(JSON.stringify(loggedInUser));
      setUser(foundUser);
      console.log(foundUser)
    }
  }, []);
  let text = localStorage.getItem("user");
let obj = JSON.parse(text);

 
const title = obj.tokens.username

  return (
    <div className='Doctor_Patient_info'>
      <Header   title={title}/>
      <div className="Doctor_Patient_info_box">
        <div className="box">
          <div className="Box-Icon">
            <Link  to={'/Doctor/Session/Session'} className="Link" title="New Session">
            <img src={logo1} alt="" className='Box-img'/>
          <h4>
           Session
          </h4>
            </Link >
         
          </div>
          <br></br>
          <div className="Box-Icon">
          <Link  to={'/Doctor/Session/MyPatient'} className="Link">
          <img src={logo2} alt=""  className='Box-img'/>
          <h4>
          My Patients
          </h4>
          </Link >
          </div>
        </div>
      </div>
    </div>

  )
}
