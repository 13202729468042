import * as React from 'react';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import PersonIcon from '@mui/icons-material/Person';
import './Table.css';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Active,DisActive } from "../Models/model"
import Swal from "sweetalert2";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import api from "../api/index";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
function CreateInsurance(name,startdate,enddate)  {
  
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  api.post(`/insurance`, {
    startdate: startdate,
    name: name,
    enddate:  enddate,

  },
    { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
      console.log(error.response.data)
      if (error.response.data) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error + '!',

        })
        return 0;

      }

    })
  Swal.fire({
    icon: 'success',
    title: 'success...',
    text: 'Insurance add it',

  }).then(() =>
    window.location.reload()
  );
  
}
function Add() {
  (async () => {
    var a = 0 ;
    const { value: formValues } = await Swal.fire({
      title: "معلومات التأمن",
      showCancelButton: true,
     
      html:
        '<div class="block" style="direction:ltr;">' +
        '<div class="Box" style="justify-content: space-around;">' +
        '<div style="width:100%"><label class="down"> name </label><br/><input id="name" class="Text-end" style="width:90%;padding:5px" required>  </div>' +
       

        "</div>" +  
        
        '<div class="Box" style="justify-content: space-around;width:100%">' +
        '<div style="width:100%"><label class="down"> تاريخ نهاية العقد</label><br/><input     name="enddate" id="enddate"     class="Text-end" type="date" style="width:90%;padding:5px" required>  </div>' +

        '<div style="width:100%"><label class="down">  تاريخ بداية العقد</label><br/><input name="startdate" id="startdate" class="Text-end" type="date" style="width:90%;padding:5px" required>  </div>' +
      
        "</div>" +
        "</div>" +
        "</div>" +

        "</div>",
      width: 1000,

      preConfirm: () => {
        if ( document.getElementById("startdate").value <  document.getElementById("enddate").value) {

          return [
          
         
          
            CreateInsurance(   document.getElementById("name").value,
            document.getElementById("startdate").value,
            document.getElementById("enddate").value,)
         
          ];
          
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: '!تاريخ بدايه عقد اكبر من تاريخ نهايه العقد',
  
          })
        }

      },
    }
    );
    
  })();
}



export default function ServicesList() {
  const columns = [

    { field: 'name', headerName: 'اسم الشركة', width: 200 },
    { field: 'startdate', headerName: 'تاريخ بداية العقد', width: 250 },
    { field: 'enddate', headerName: 'تاريخ نهاية العقد', width: 250 },
    {
      field: "actionEdit",
      headerName: "تعديل",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
    
          handleEdit(params)
        };
  
        return <Button onClick={onClick}><ModeEditIcon /></Button>;
      },
    },
  
   {
      field: "diasvtice",
      headerName: "تنشيط",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          
          const tokens = JSON.parse(localStorage.getItem('tokens'));
          console.log(params.row)
          if(params.row.status=='Active'){
           
            Active(params,'insurance')
          }else{
            DisActive(params,'insurance')
          }
        };
  if(params.row.status=='Active'){
    return <Button onClick={onClick}> <PersonIcon  style={{ color: '#ea0075' }}  /></Button>
  }else{
    return <Button onClick={onClick}> <PersonOffIcon style={{ color: 'red' }}  /></Button>
  }},}
  ];
  const handleEdit = params => {
    (async () => {
     
    
      const name =params.row.name
       const uuid =params.row.uuid
      const enddate =params.row.enddate
      const startdate =params.row.startdate
  
      const { value: formValues } = await Swal.fire({
        title: "معلومات المستخدم",
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> الاسم</label><br/><input id="name" class="Text-end"  type="text" style="width:90%;padding:5px" value='+name+' >  </div>' +
         
          "</div>" +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> تاريخ النهايه </label><br/><input id="enddate" type="date"class="Text-end" style="width:90%;padding:5px" value='+enddate+'>  </div>' +
          
          '<div style="width:100%"><label class="down"> رقم البدايه</label><br/><input id="startdate"  type="date" class="Text-end" style="width:90%;padding:5px" value='+startdate+' >  </div>' +
          "</div>" +
          "</div>" +
          "</div>",
        width: 1000,
      
        preConfirm: () => {
          
          return [
            document.getElementById("name").value,
            document.getElementById("startdate").value,
            document.getElementById("enddate").value,
         update( document.getElementById("name").value,  document.getElementById("startdate").value,document.getElementById("enddate").value,uuid)
           
          ];
          
        },
      
        
      }
      );
  function update(name,startdate,enddate,uuid) {
  const tokens = JSON.parse(localStorage.getItem('tokens'));
  
  api.put(`/insurance?uuid=`+uuid , {
    startdate: startdate,
    name: name,
    enddate :enddate,
  },
    { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
      if (error.response.data) {
        console.log(error.response.data.error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error + '!',
  
        })
        return 0;
  
      }
  
    })
  Swal.fire({
    icon: 'success',
    title: 'success...',
    text: 'insurance update',
  
  }).then(() =>
  window.location.reload()
  );
  }
    
     
    })()};
  
  const [tableData, setTableData] = React.useState([])


  const handleGetRowId = (e) => {
    return e.uuid
  }
  
  React.useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    api.get('/insurances', {
      headers: {
        'Authorization': `Bearer ${tokens["access_token"]}`
      }
    }).then((data) => data['data']).then((data) => {
      data.forEach(insurance => {
      
        insurance.startdate = new Date(  insurance.startdate)
    var a = Math.floor(insurance.startdate.getTime() ) 
a =new  Date(a);
   console.log(a.toISOString().split('T')[0])
   insurance.startdate =a.toISOString().split('T')[0]
   insurance.enddate = new Date(  insurance.enddate)
   var b= Math.floor(insurance.enddate.getTime() ) 
b =new  Date(b);
  console.log(b.toISOString().split('T')[0])
  insurance.enddate =b.toISOString().split('T')[0]
      })
      setTableData(data);
    } );
  }, [])
  console.log(tableData);
  return (
    <div>
         <div className="flex" style={{ padding: "10px 20px", marginTop: "5px ", }}>
      
      <div className="">
      <AddCircleIcon className="flex" onClick={() => Add()} />
      </div>
      <div className="">
      <label className="down"  >
        
        {"إضافة "}
        
      </label>
  
      </div>
              
      </div>
     
    
      <div style={{ height: 770, width: '100%', background: 'white', direction: "rtl" }} className="table" >
      <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={12}
          getRowId={handleGetRowId}
         
          rowSelection='single'
          rowsPerPageOptions={[6]}
          

          className="DataGrid"
        />
      </div>
    </div>

  );
}
