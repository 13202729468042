import  React , { useState, useEffect } from 'react';
import api from "../../api/index";
import Button from '@mui/material/Button';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PersonIcon from '@mui/icons-material/Person';
import './Table.css';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Active,DisActive } from "../../Models/model"
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { createTheme } from '@mui/material/styles';
import { DataGrid, arSD } from '@mui/x-data-grid';
import { CreatePatient } from "../../Models/model"
import Swal from "sweetalert2";


export default function DataTable() {
  const [TableData, setTableData] = useState([]);
  const [Insurances, setInsurances] = useState([]);
  
const columns = [
  { field: 'name', headerName: 'اسم المريض' , flex: 1  },
  { field: 'ismale', headerName: 'الجنس', flex: 1 },
  { field: 'phone_number', headerName: 'رقم الهاتف ', flex: 1 },
  { field: 'birthday', headerName: 'تاريخ الميلاد ', flex: 1 },
  { field: 'balance', headerName: ' الرصيد ', flex: 1 },

  {
    field: "actionEdit",
    headerName: "تعديل",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
      handleEdit(params)
      };

      return <Button onClick={onClick}> <ModeEditIcon /></Button>;
    },
  },
  {
    field: "diasvtice",
    headerName: "تنشيط",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        if(params.row.status=='Active'){
         
          Active(params,'patient')
        }else{
          DisActive(params,'patient')
        }
      };
if(params.row.status=='Active'){
  return <Button onClick={onClick}>  <PersonIcon    /></Button>
}else{
  return <Button onClick={onClick}> <PersonOffIcon style={{ color: 'red' }}  /></Button>
}
   
    },
  },
  {
    field: "aa",
    headerName: "تأمين",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        

        if(params.row.isinstanced== true){
        
          insurances(params,Insurances)
        }
      };
      
if(params.row.isinstanced== true){
  return <Button onClick={onClick}>  <AddBusinessIcon    /></Button>
}else{
  
}
   
    },
  },
  // {
  //   field: 'action',
  //   headerName: 'Action',
  //   sortable: false,
  //   renderCell: (params) => {


  //     return  <Link to="/Doctor/Session/PatientInfo">
  //     <Button>Click</Button>
  //   </Link> ;
  //   },},
  //   {
  //     field: 'action2',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params) => {


  //       return  <Link to="/View">
  //       <Button>View</Button>
  //     </Link> ;
  //     },}
];

         
          function insurances(params,Insurances) {




  (async () => {
    console.log(Insurances)
var a=0;
    const { value: Insurance } = await Swal.fire({
      
      title: 'اختيار شركه تأمين',
      html:
    
      '<div class="block" style="direction:ltr;">' +
      '<div class="Box" style="justify-content: space-around;">' +
   
      '<div style="width:100%"><label class="down"> شركه تأمين</label><br/> <select class="Text-end" style="height:35px;width:99%;direction: rtl;padding:5px"  id="name">"'+Insurances.map((e) =>'<option value="' + e.uuid +'">'+ e.name +'</option>"')+'</select>  </div>' +
  
      "</div>" +
      '<div class="Box" style="justify-content: space-around;width:100%">' +
      '<div style="width:100%"><label class="down"> تاريخ نهاية العقد</label><br/><input     name="enddate" id="enddate"     class="Text-end" type="date" style="width:90%;padding:5px" required>  </div>' +

      '<div style="width:100%"><label class="down">  تاريخ بداية العقد</label><br/><input name="startdate" id="startdate" class="Text-end" type="date" style="width:90%;padding:5px" required>  </div>' +
    
      "</div>" +
      "</div>",
      width: 1000,
      showCancelButton: true,

      preConfirm: () => {
        if ( document.getElementById("startdate").value <  document.getElementById("enddate").value) {
          a=1
                   return [
                   
                    handleSubmit(document.getElementById("name").value,document.getElementById("startdate").value,document.getElementById("enddate").value,params)
                 
                  
                   ];
                   
                 }else{
                   Swal.fire({
                     icon: 'error',
                     title: 'Oops...',
                     text: '!تاريخ بدايه عقد اكبر من تاريخ نهايه العقد',
           
                   })
                 }
         
               },
             }
             );
           
    
     
 
  })();
}
const handleSubmit = (name,startdate,enddate,params) => {


  const tokens = JSON.parse(localStorage.getItem('tokens'));

  api.post(`/patient/insurance`, {
    patient_uuid:params.row.uuid,
    uuid:name,
    startdate:startdate,
    enddate:enddate
  },
    { headers: {'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
      console.log(error.response.data)
      if (error.response.data) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error + '!',

        })
        return 0;

      }

    })
  Swal.fire({
    icon: 'success',
    title: 'success...',
    text: 'Treatment add it',

  }).then(() =>
  window.location.reload()
);
  
}

const handleEdit = params => {
  (async () => {
   console.log(params)
    const  uuid =params.row.uuid
    const name =params.row.name
    const ismale =params.row.ismale
    const phone_number =params.row.phone_number
    const birthday =params.row.birthday
    const address =params.row.address
    const isinstanced  =params.row.isinstanced
    var a=''
    var b =true
    if(ismale=='أنثي'){
 a='ذكر';
 b =true
    }
    if(ismale=='ذكر'){
      a='أنثي';
      b =false
         }
    const { value: formValues } = await Swal.fire({
      title: "معلومات المستخدم",
     
      showCancelButton: true,
    
      html:
    
        '<div class="block" style="direction:ltr;">' +
        '<div class="Box" style="justify-content: space-around;">' +
     
        '<div style="width:100%"><label class="down">جنس</label><br/> <select class="Text-end" style="height:35px;width:90%;direction: rtl;padding:5px"  id="ismale"><option value="'+ismale+'">'+ismale +' </option><option value="'+b+'">'+a+'</option></select>  </div>' +
        '<div style="width:100%"><label class="down"> الاسم</label><br/><input id="name" class="Text-end" required style="width:90%;padding:5px" value="'+name+'" >  </div>' +
        "</div>" +
        '<div class="Box" style="justify-content: space-around;">' +
        '<div style="width:100%"><label class="down"> رقم الهاتف</label><br/><input id="phone_number"required type="number" class="Text-end" style="width:90%;padding:5px" value="'+phone_number+'" >  </div>' +
        '<div style="width:100%"><label class="down"> تاريخ الميلاد </label><br/><input id="birthday"required type="date"class="Text-end" style="direction: rtl;width:90%;padding:5px" value="'+birthday+'">  </div>' +
        "</div>" +
        '<div class="Box" style="justify-content: space-around;">' +
     
        '<div style="width:100%"><label class="down">تأمين</label><br/> <select class="Text-end"required style="height:35px;width:90%;direction: rtl;padding:5px"  id="isinstanced" ><option value="'+isinstanced+'">'+isinstanced+'</option><option value="'+!isinstanced+'">'+!isinstanced+'</option></select>  </div>' +
        '<div style="width:100%"><label class="down">  العنوان</label><br/><input id="address"required class="Text-end" style="width:90%;padding:5px" value="'+address+'" />' + 
        "</div>" +
        "</div>" +
        "</div>",
   
      width: 1000,
    
      preConfirm: () => {
        
        return [
         
        update( document.getElementById("name").value,  document.getElementById("phone_number").value,document.getElementById("ismale").value, document.getElementById("birthday").value,uuid,document.getElementById("isinstanced").value,document.getElementById("address").value)
         
        ];
        
      },
    
      
    }
    );
function update(name,phone_number,ismale,birthday,uuid,isinstanced,address) {
const tokens = JSON.parse(localStorage.getItem('tokens'));
var isTrueSet = (isinstanced === 'true');

console.log(name)
api.put(`/patient?uuid=`+uuid , {
  name: name,
  address:address,
  phone_number :phone_number,
  ismale: (ismale === 'true'),
  isinstanced:isTrueSet,
  birthday :birthday,
},
  { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
    if (error.response.data) {
      console.log(error.response.data)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.error + '!',

      })
      return 0;

    }

  })
Swal.fire({
  icon: 'success',
  title: 'success...',
  text: 'patient update',

}).then(() =>
window.location.reload()
);
}
  
   
  })()};
   //API object tableData for view data 
   const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
        
      },
    },
    arSD,
  );
   function Add() {
    (async () => {
      var ismale = false
      const { value: formValues } = await Swal.fire({
        title: "معلومات المريض",
        showCancelButton: true,
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> اسم المريض  </label><br/><input id="getRooms" class="Text-end" style="width:94%;padding:5px" required>  </div>' +

          "</div>" +
          '<div class="Box" style="justify-content: space-around;padding-top:10px">' +
          '<div class="Box" style="justify-content: space-around;width:100%">' +
          '<div style="width:100%"><label class="down"> العنوان </label><br/><input id="address" class="Text-end" style="width:90%;padding:5px" required>  </div>' +
          '<div style="width:100%"><label class="down"> تاريخ الميلاد</label><br/><input id="birthday" class="Text-end" type="date" style="width:90%; direction: rtl;padding:5px" required>  </div>' +
          "</div>" +
          "</div>" +
          '<div class="Box" style="justify-content: space-around;width:100%">' +
          '<div style="width:100%"><label class="down">رقم الهاتف</label><br/><input id="phone_number" class="Text-end" style="width:90%;padding:5px" required>  </div>' +

          '<div style="width:100%"><label class="down">جنس</label><br/> <select class="Text-end" style="height:35px;width:90%;direction: rtl;padding:5px"  id="sex"><option value="false">أنثي</option><option value="true">ذكر</option></select>  </div>' +
          "</div>" +
          "</div>" +
          "</div>" +

          "</div>",
        width: 1000,

        preConfirm: () => {
        
          console.log(ismale)
          return [
            document.getElementById("getRooms").value,
            document.getElementById("address").value,
            document.getElementById("birthday").value,
            document.getElementById("phone_number").value,
            document.getElementById("sex").value,
            
          ];
        },
      }
      );

      if (formValues) {
        const json = Swal.fire(JSON.stringify(formValues));

        CreatePatient(formValues)
      }
    })();
  }
  
  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
  

    

    api.get('/patients', {
      headers: {
        'Authorization': `Bearer ${tokens["access_token"]}`
      }
    }).then((data) => data['data']).then((data) => {
      data.forEach(patient => {
        patient.ismale? patient.ismale ="ذكر":patient.ismale ="أنثي" 
        patient.birthday = new Date(  patient.birthday)
    var a = Math.floor(patient.birthday.getTime() ) 
a =new  Date(a);
   console.log(a.toISOString().split('T')[0])
   patient.birthday =a.toISOString().split('T')[0]
      })
      console.log(data)
      setTableData(data)
    } );
    
    api.get('/insurances', {
      headers: {
        'Authorization': `Bearer ${tokens["access_token"]}`
      }
    }).then((data) => data['data']).then((data) => {
    
      setInsurances(data)
    } );
  }, []);
     //get id
  const handleGetRowId = (e) => {
    return e.uuid
  }
  return (
    <div>
   <div className="flex" style={{ padding: "10px 20px", marginTop: "5px ", }}>
      
      <div className="">
      <AddCircleIcon className="flex" onClick={() => Add()} />
      </div>
      <div className="">
      <label className="down"  >
        
        {"إضافة "}
        
      </label>
  
      </div>
              
        
         
  
        </div>
        <div style={{ height: 800, width: '100%' }}>
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ flexGrow: 1  }}>

  <DataGrid
    rows={TableData}
    columns={columns}
    pageSize={12}
    getRowId={handleGetRowId}
   
    rowSelection='single'
    rowsPerPageOptions={[6]}
    
 style={{ direction: "rtl" }}
    // /className="DataGrid"
    />
        </div>
      </div>
    </div>



    </div>

  );
}
