import React, { useState } from "react";

import Navbar from "../../Component/Navbar/Navbar";
import "./Doctor_Patient_info.css";
import { Button, Radio } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import api from "../../api/index";
import $ from "jquery";
import Swal from "sweetalert2";

export const PatientAdd = () => {
  
  const [tableData, setTableData] = useState([]);
  const [doctornote, setdoctornote] = useState("");
  const [formInputData, setformInputData] = useState({
    Name: "",
    uuid: "",
    quantity: 0,
  });
  const handleDelete = (id) => {
    console.log(tableData);
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.value) {
        console.log(tableData);

        const employeesCopy = tableData.filter(
          (employee) => employee.uuid !== id
        );

        setTableData(employeesCopy);
      }
    });
  };

  const handleChange = (evnt) => {
    var value = $("#" + evnt.target.name).val();
    var uuidvalue;
    console.log(value);

    if (evnt.target.name == "quantity") {
      var newInput = (data) => ({
        ...data,
        [evnt.target.name]:  parseInt(evnt.target.value),
      });
    }

    if (evnt.target.name == "Name") {
      uuidvalue = $('#treatments [value="' + value + '"]').data("value");

      newInput = (data) => ({
        ...data,
        [evnt.target.name]: evnt.target.value,
        uuid: uuidvalue,
      });
    }

    setformInputData(newInput);
    console.log(formInputData);
  };
  const onChange = (evnt) => {
    setdoctornote(evnt.target.value);
    console.log(evnt.target.value);
  };


  const handleSubmit = (evnt) => {
    evnt.preventDefault();
    const checkEmptyInput = !Object.values(formInputData).every(
      (res) => res === ""
    );
    if (checkEmptyInput) {
      const newData = (data) => [...data, formInputData];
      setTableData(newData);
      const emptyInput = { Name: "", quantity: 0 };
      setformInputData(emptyInput);
    }
  };
  const [PatientData, setPatientData] = useState([]);
  const [Patient, setPatient] = useState([]);
  const [ListData, setListData] = useState([]);
  const [sex, setsex] = useState([]);
  let { id } = useParams();
  useEffect(() => {
    console.log("session is here " + id);
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    api
      .get(`/session`, {
        params: {
          uuid: id,
        },
        headers: {
          Authorization: `Bearer ${tokens["access_token"]}`,
        },
      })
      .then((data) => data["data"])
      .then((data) => {
        setPatientData(data);
        setPatient(data["patient"]);
        if (Patient.ismale == true) {
          setsex("male");
        } else {
          setsex("female");
        }
        console.log(sex);
        console.log(data);
      });

    api
      .get("/treatments", {
        headers: {
          Authorization: `Bearer ${tokens["access_token"]}`,
        },
      })
      .then((data) => data["data"])
      .then((data) => setListData(data));
    console.log(ListData);
  }, []);

  return (
    <div>
      <Navbar />
      <div className="block1" style={{ padding: "20px" }}>
        <div className="">
          {/* first row */}
          <div className="row2Items">
            <div className="itemRow">
              <label style={{ float: "right" }}> اسم المريض</label>
              <br />
              <input
                className="Text-end twoTextField paddingTextField"
                disabled
                value={Patient["name"]}
              />
            </div>
            <div className="itemRow">
              <label style={{ float: "right" }}> تاريخ إضافة</label>
              <br />
              <input
                className="Text-end twoTextField paddingTextField"
                disabled
                type="
                      date"
                value={PatientData["created"]}
              />
            </div>
          </div>
          <br />
          <div className="row2Items">
            <div className="itemRow">
              <label style={{ float: "right" }}>تاريخ الميلاد </label>
              <br />

              <input
                className="Text-end twoTextField paddingTextField"
                disabled
                value={Patient["birthday"]}
              />
            </div>
          </div>
          {/* <div className="Box">
                  <textarea className="Text-textarea" label="label" />
                  <label className="down">Note</label>
              </div> */}
          <div className="row2Items" style={{ paddingTop: "10px" }}></div>
          <div className="row2Items" style={{ paddingTop: "10px" }}>
            <div className="itemRow">
              <label style={{ float: "right" }}> ملاحظات الاستقبال</label>
              <br />

              <input
                className="Text-end twoTextField paddingTextField"
                value={PatientData.recipientnote}
                disabled
              />
            </div>
            <div className="itemRow">
              <label style={{ float: "right" }}> ملاحظات الدكتور</label>
              <br />
              <input
                className="Text-end twoTextField paddingTextField"
                value={doctornote}
                onChange={onChange}
              />
            </div>
          </div>
          <FormInput
            handleChange={handleChange}
            formInputData={formInputData}
            handleSubmit={handleSubmit}
            ListData={ListData}
          />
          <br /> <br />
          <Button
            variant="contained"
            className="Save-Button"
            onClick={handleSubmit}
            style={{
              fontFamily: "Tajawal",
              textAlign: "center",
              minwidth: "100%",
            }}
          >
            أضافه{" "}
          </Button>
          <br></br> <br /> <br />
          <div style={{ display: "flex", direction: "rtl" }}>
            <div className="DataGrid1">
              <table className="table" id="customers">
                <thead>
                  <tr>
                    <th>
                      <h2
                        style={{
                          direction: "rtl",
                          color: "white",
                          float: "right",
                        }}
                      >
                        {" "}
                        الرقم
                      </h2>
                    </th>
                    <th>
                      <h2
                        style={{
                          direction: "rtl",
                          color: "white",
                          float: "right",
                        }}
                      >
                        اسم علاج
                      </h2>{" "}
                    </th>
                    <th>
                      <h2
                        style={{
                          direction: "rtl",
                          color: "white",
                          float: "right",
                        }}
                      >
                        الكيمه{" "}
                      </h2>{" "}
                    </th>
                    <th>
                      <h2
                        style={{
                          direction: "rtl",
                          color: "white",
                          float: "right",
                        }}
                      >
                        حذف{" "}
                      </h2>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.Name}</td>
                        <td
                       
                        >
                          {data.quantity}
                        </td>
                        <td>
                          {" "}
                          <button
                            onClick={() => handleDelete(data.uuid)}
                            className="button muted-button"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <br></br> <br /> <br />
          <div className="Table-center" style={{ float: "right" }}>
            <Button
              variant="contained"
              className="Save-Button"
              style={{ fontFamily: "Tajawal", textAlign: "center" }}
              onClick={() => sessionsubmit(id, tableData, doctornote)}
            >
              حفظ{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
function FormInput({ handleChange, formInputData, ListData }) {
  return (
    <div className="row2Items1">
      <div className="itemRow">
        <label style={{ float: "right" }}> الكميه</label>
        <br />

        <input
          type="number"
          onChange={handleChange}
          value={formInputData.quantity}
          name="quantity"
          className="Text-end twoTextField paddingTextField"
          placeholder="quantity"
        />
      </div>

      <div className="itemRow">
        <label style={{ float: "right" }}> اسم علاج</label>
        <br />
        <input
          list="treatments"
          placeholder="Name"
          id="Name"
          name="Name"
          onChange={handleChange}
          value={formInputData.Name}
          className="Text-end twoTextField paddingTextField"
        />
        <datalist id="treatments">
          {ListData.map((Data, index) => (
            <option key={Data.uuid} value={Data.name} data-value={Data.uuid}>
              {" "}
            </option>
          ))}
        </datalist>
      </div>
    </div>
  );
}

export function sessionsubmit(id, tableData, doctornote) {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  console.log(id, tableData, doctornote);
  api
    .post(
      `/session/submit?uuid=` + id,
      {
        status: "closed",
        doctornote: doctornote,
        treatments: tableData,
      },
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${tokens["access_token"]}`,
        },
      }
    )
    .catch(function(error) {
      if (error.response.data) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.error + "!",
        });
        return 0;
      }
    });
  Swal.fire({
    icon: "success",
    title: "success...",
    text: "Patient Create",
  }).then(() => window.location.reload());
}
