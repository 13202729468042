import React, { useRef } from 'react'
import "./teeth.css";
const Teeth = () => {
    // const teethTopList = ["tt1","tt2","tt3","tt4","tt5","tt6","tt7","tt8","tt9","tt10","tt11","tt12","tt13","tt14"];
    // const teethBottomList = ["tb1","tb2","tb3","tb4","tb5","tb6","tb7","tb8","tb9","tb10","tb11","tb12","tb13","tb14"];
    const ref = useRef(null);

    const handleClick = event => {
        // console.log("you just clicked " + event.currentTarget.id);
        // console.log("you just ref " + ref.current.id);

        const selected = document.getElementById(event.currentTarget.id);

        selected.style.fill = "#F98A6B";

    };
    const handleDoubClick = event => {
        // console.log("you just clicked " + event.currentTarget.id);
        // console.log("you just ref " + ref.current.id);

        const selected = document.getElementById(event.currentTarget.id);

        selected.style.fill = "#30E6CB";

    };
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 541.49 726.76">
            {/* <style>{css}</style> */}
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt8"
                d="M 345.5 95 l 0.2 0 a 66.3 66.3 0 0 1 7.8 4.3 c 1.1 0.8 1.7 1.6 1.3 3.1 c -1 3.6 -2.4 7.1 -3.6 10.7 c -1.9 5.4 -6.2 9.3 -8.7 14.3 c -0.9 1.7 -1.4 3.6 -3.2 4.7 c -2.6 1.7 -5.3 3.4 -7.8 5.3 c -8.7 6.5 -22.2 0.2 -25 -7.6 c -2.1 -5.9 -4.6 -11.7 -5.9 -17.9 A 76.8 76.8 0 0 1 299 93.4 c 0 -1.4 0.3 -2.5 2.1 -2.8 c 9.2 -1.1 18.3 -1.7 27.5 -0.2 A 125.4 125.4 0 0 1 345.5 95 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb7"
                d="M 255 751.9 l -1.1 -0.1 c -4.2 -1.6 -8.6 -2.6 -12.7 -4.8 a 3.4 3.4 0 0 1 -2 -4.3 a 56.5 56.5 0 0 1 2.8 -9.6 c 2.4 -5.9 6.1 -11 9.7 -16.1 c 3.5 -6.6 10 -9.2 16.4 -11.9 a 7.4 7.4 0 0 1 5.9 0 c 2.4 0.9 4.8 1.8 7.1 2.8 a 10.3 10.3 0 0 1 6 6.2 c 0.5 1.3 1 2.6 1.6 3.9 c 0.2 0.8 0.4 1.7 0.7 2.5 a 103.5 103.5 0 0 1 6 27.3 c 0.3 3.7 -1.9 6.3 -5.6 7 a 41.7 41.7 0 0 1 -4.2 0.4 a 101.8 101.8 0 0 1 -21.1 -1.1 l -0.5 0 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb10"
                d="M 396.1 666.6 c 3.4 -1.6 7 -0.9 10.6 -1.1 a 19 19 0 0 0 5.5 0.9 l 0.8 0.1 a 6.1 6.1 0 0 0 3.3 0.9 l 0.9 0.2 c 2.9 1.9 6.3 2.8 9.4 4.2 c 11.1 4.9 15 12 14.7 23 c -0.1 1.9 -1.3 3.4 -1.1 5.3 c -1.8 5 -5.8 7.9 -10.1 10.4 a 6.7 6.7 0 0 0 -2.6 2.1 c -4.2 1.6 -8.4 3.2 -12.8 4.5 c -5.1 1.6 -8.3 -0.7 -11.3 -4.3 c -4.4 -5.3 -6.5 -11.5 -8.2 -17.9 c -1.6 -5.7 -3.8 -11.3 -4.8 -17.1 c -0.2 -1.3 -0.5 -2.6 -0.7 -3.9 a 0.2 0.2 0 0 0 0.1 -0.2 c 1.3 -3.1 4.1 -4.8 6.2 -7.2 Z"
                transform="translate(-30.6,-63)" />
            <path id="line"
                d="M 418.6 426 c -40.2 0.2 -80.5 0.6 -120.7 0.4 c -35.9 -0.1 -71.9 0 -107.8 -0.3 c -13.8 -0.1 -27.7 -0.4 -41.5 -0.4 c 9 -1.8 18.1 -1.5 27.2 -1.7 c 61.6 -1.2 123.3 -0.6 184.9 -0.7 c 12.4 0 24.9 0.3 37.3 0.4 a 1.8 1.8 0 0 0 1.6 0 h 2.1 l 12.6 0.3 C 415.4 425.8 417.4 425 418.6 426 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt14"
                d="M 538.8 342.8 c -1.3 7.6 -1.4 15.3 -1.4 23.1 c 0 2.1 -0.3 4.2 -0.5 6.3 l 0 0.2 c -0.8 5.7 -3 10.2 -8.8 12.4 s -11.8 4.3 -17.9 6 l -1.6 0.5 l -4.3 1 c -5.7 1.3 -11.5 2.1 -17.3 0.7 a 4 4 0 0 1 -2.8 -1.2 c -3.3 -2.6 -6.6 -5.2 -10 -7.7 a 7.2 7.2 0 0 1 -2.3 -3.5 c -4.6 -12.1 -5.8 -24.1 -0.2 -36.3 a 5.4 5.4 0 0 1 1.1 -2.1 c 4.7 -3.7 8.9 -8 15.1 -9.7 a 143.3 143.3 0 0 1 28.4 -5.3 a 27.8 27.8 0 0 1 17.9 4.8 C 537.9 334.5 539.6 338 538.8 342.8 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb3"
                d="M 157.9 609.6 q -1.8 3.2 -3.5 6.5 a 14.7 14.7 0 0 1 -4.7 5.6 c -2.4 1.6 -4.2 4 -6.4 5.9 c -9.6 5.4 -19.6 9.6 -31 8.5 c -7.9 -2.5 -13.7 -7.2 -16.4 -15.2 a 15.9 15.9 0 0 1 -0.7 -8.5 c 1.6 -8.1 6.8 -13.3 13.4 -17.5 c 6.9 -4.4 14.4 -6.5 22.3 -7.7 c 6.2 -0.9 12.3 -1.3 18.4 0.9 C 158 591.2 162.2 601.6 157.9 609.6 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb6"
                d="M 241.3 714.6 c 0 3.5 -0.4 7 -0.7 10.5 a 28.6 28.6 0 0 0 -1.6 6.3 c -1 4.7 -1.2 9.7 -5.8 12.8 c -4.2 0 -8.1 -1.4 -12.1 -2.3 c -9 -2.1 -17.3 -5.5 -24.5 -11.5 c -2.1 -1.8 -5 -2.5 -6.4 -5.2 c -1.9 -3.6 -1.9 -4.9 0.6 -8.1 a 81 81 0 0 1 14 -13.4 a 10.9 10.9 0 0 0 1.5 -1.1 c 4.3 -4.9 10.4 -6.2 16 -8.5 c 3 -1.2 6.2 -0.4 9.3 1.2 C 240 699.3 241.2 706.7 241.3 714.6 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb4"
                d="M 174.2 642.7 c -0.3 3.1 -0.1 6.3 -1.4 9.2 l -0.4 0.9 a 21.3 21.3 0 0 1 -3.7 7 l 0 0 c -1.6 3.9 -4.9 6.2 -8.1 8.7 c -7.5 6 -15.8 7.7 -25.1 5.4 c -8.3 -2 -13.7 -7.4 -15.3 -15.3 c -1.5 -7.4 1.7 -13.9 7.5 -18.9 c 1.8 -1.2 3.7 -2.3 5.5 -3.5 a 35.8 35.8 0 0 1 7.9 -4.6 a 50.1 50.1 0 0 1 22.7 -2.9 h 0 l 4.8 2.1 C 172.6 633.8 174.6 637.6 174.2 642.7 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt3"
                d="M 156.7 247.8 c -3 8.6 -10.5 10.3 -18.2 11.1 a 33.5 33.5 0 0 1 -17.7 -2.9 c -2.2 -1 -4.7 -1.4 -7 -2.3 a 30.2 30.2 0 0 1 -17.3 -16 c -4.9 -10.3 2.6 -22.4 11.6 -25.9 c 1 -0.2 2.1 -0.3 3.1 -0.5 c 9.4 -1.7 18.3 -0.8 26.3 4.7 c 2.9 2 6.2 3.5 8.9 5.8 c 3 3.8 6.8 6.9 8.9 11.3 a 8.3 8.3 0 0 1 1.1 2.9 c 0.2 0.5 0.3 1 0.5 1.5 v 0.1 C 158.3 241.1 158.7 244.5 156.7 247.8 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt10"
                d="M 443.2 158.6 c -0.3 0.5 -0.6 0.9 -0.9 1.4 A 17.7 17.7 0 0 1 435 170.4 c -1 0.7 -2 1.5 -2.9 2.2 l -0.1 0 a 3.1 3.1 0 0 0 -2 1.2 l -0.5 0.5 c -3.2 1.9 -7 2.2 -10.4 3.2 h 0 a 4.5 4.5 0 0 0 -2.8 0.5 a 0.5 0.5 0 0 0 -0.4 0.1 a 30 30 0 0 0 -6.9 1 l -1.6 0.1 c -2.6 -0.1 -5.2 0.5 -7.8 -0.3 c -0.1 -0.1 -0.2 -0.3 -0.3 -0.4 c -5.2 -3.2 -6.7 -7.9 -5.5 -13.6 c 0.2 -1 0.4 -2 0.7 -3 c 2.1 -9 2.2 -18.5 8.1 -26.4 c 1.3 -1.7 2.8 -3.3 4 -5.1 l 0 0 a 17.2 17.2 0 0 1 6.9 -1.4 c 3.8 -0.3 7.3 1 10.8 1.7 c 2.4 1 4.8 2 7.2 3.1 a 4.2 4.2 0 0 1 2.6 2.4 a 13.4 13.4 0 0 1 5.3 5 c 1 1.4 1.8 2.9 2.7 4.5 a 2.9 2.9 0 0 1 0.5 1.1 A 13.3 13.3 0 0 1 443.2 158.6 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb11"
                d="M 471.3 662.4 c -0.2 1.8 -1.9 2.7 -2.8 4 l -3.2 2.7 c -7.2 5.9 -15.4 6.6 -24.1 4.2 l -0.1 0 a 8.8 8.8 0 0 1 -5.1 -2.1 l -0.1 -0.1 l -0.6 -0.4 l 0.1 0 l -0.9 -0.6 c -3.5 -1.2 -5.3 -4.3 -7.7 -6.7 l 0 0 c -1.4 -2.4 -2.9 -4.8 -4.3 -7.2 v 0 l -1.4 -2.8 c 0 -0.2 0 -0.5 0 -0.7 a 28.8 28.8 0 0 1 -1.1 -15.4 c 0.6 -2.8 2.5 -4.9 4.9 -6.5 l -0.1 0 l 0.6 -0.1 l 1 -0.4 l 0.2 -0.1 l 1.4 -0.6 l 0 0 l 1.5 -0.5 h 0 c 3.5 -1.7 7.3 -1 10.9 -0.8 c 0.7 0 1.4 0.2 2 0.3 c 2.4 1.2 5.2 1.5 7.4 3.1 l 0.1 0 l 1 0.5 l 0.6 0.2 l 0.5 0.3 h 0 l 0.7 0.4 a 51.1 51.1 0 0 1 9.9 6.7 a 3.3 3.3 0 0 0 0.8 0.7 C 472.5 644.7 472.4 653.4 471.3 662.4 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt4"
                d="M 165 218.1 l -2.1 0 a 18.6 18.6 0 0 1 -6.3 0.1 c -3.4 0.5 -6.6 0.2 -9.1 -2.6 L 146 215 l -0.8 -0.5 l -0.8 -0.1 l -0.6 -0.4 a 0.7 0.7 0 0 1 -0.7 -0.6 c -1.6 0.4 -2.8 -0.7 -4 -1.4 c -2.7 -1.5 -5.5 -3 -8.2 -4.5 l 0 0 a 3.8 3.8 0 0 0 -2.1 -1.6 l -0.5 -0.4 l -4.5 -5.9 l -0.1 -0.2 l -0.1 -0.1 c -2.4 -5.6 -1.6 -11.1 0.1 -16.7 a 8.2 8.2 0 0 1 4 -5 c 4.1 -2.1 8 -4.7 12.7 -5.6 c 5.4 -1.1 10.6 0.1 15.6 2 c 0.6 0.2 1.1 0.6 1.7 0.8 c 5.1 1.5 8.7 4.3 9.8 9.8 c 0.5 2.1 2.3 3.5 3.5 5.2 c 1.6 1.5 1.9 3.5 2.3 5.5 c 0.4 2.4 0.5 4.9 0.8 7.3 C 175.3 210.3 170.9 214.7 165 218.1 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb14"
                d="M 531.6 513.9 c -4.1 2.7 -8.2 5.3 -13.1 6.3 l -0.5 0 l -0.5 0.1 l -3.1 0.1 l -1.1 0 c -3.4 -0.9 -7 -1 -10.2 -2.6 l -0.8 -0.2 l -1.7 -0.4 l -0.9 -0.2 l -0.8 -0.4 l -0.8 -0.1 a 5 5 0 0 1 -3.4 -0.9 a 0.2 0.2 0 0 1 -0.3 0 a 1.3 1.3 0 0 1 -1.1 -0.4 h 0.1 l 0 0 l -0.1 0 l 0.1 0.1 a 2.5 2.5 0 0 1 -1.8 -0.6 c -3.7 -0.4 -7.1 -2 -10.8 -2.6 l -0.1 0 a 5 5 0 0 0 -2.4 -0.9 l 0.1 0.1 l -0.7 -0.4 l -0.6 -0.4 a 0.6 0.6 0 0 0 -0.4 -0.2 a 33 33 0 0 1 -7 -8 a 0.4 0.4 0 0 0 -0.3 -0.3 l -1.8 -1.7 c -0.9 -2 -2.6 -3.7 -2.7 -6.1 c -1.8 -10 -2.5 -19.8 4.3 -28.5 l 2.1 -3.2 l 0.5 -0.6 l -0.1 0.1 l 0.6 -0.8 c 5.4 -5.5 11.2 -9.9 19.5 -9.4 a 70.9 70.9 0 0 1 14.9 3.1 c 4.9 1.4 9.8 2.8 14.7 4.2 c 6.3 1.8 9.8 6.3 12.3 12 a 33.2 33.2 0 0 1 1 9.9 a 176.2 176.2 0 0 0 1.2 24.1 A 8.7 8.7 0 0 1 531.6 513.9 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb8"
                d="M 351.8 746 l -0.5 1.1 l -0.1 0.1 c -2.1 2.9 -5.3 3.8 -8.5 4.9 c -10.9 3.6 -21.7 7.7 -33.5 5.8 a 10.2 10.2 0 0 0 -1.8 0 c -2.8 -1 -5.9 -1 -8.6 -2.2 l 0 0 l -0.6 -1.4 c -1.3 -0.7 -1.4 -1.9 -1.3 -3.1 c 0.4 -5.9 1.9 -11.7 2 -17.7 a 10.4 10.4 0 0 1 1 -2.9 a 9.9 9.9 0 0 1 0.6 -4.2 c 0.1 -0.4 0.2 -0.8 0.3 -1.2 v -0.1 c 0.1 -0.8 0.2 -1.5 0.3 -2.3 c 0.4 -0.8 0.9 -1.6 1.3 -2.5 l 2.4 -5.4 l 0.1 0 c 2.4 -3.8 4.6 -7.7 9.3 -9.2 a 13.2 13.2 0 0 1 15.7 1.6 l 2.2 1.1 l 0.5 0.3 a 38.6 38.6 0 0 1 6.4 7.1 l 0.4 0.6 l 0.1 0.1 a 5.5 5.5 0 0 1 1.7 2.4 l 0 0 l 0.5 0.6 a 5.1 5.1 0 0 1 1.7 2.5 h 0 c 2.6 4.1 6.4 7.5 7.6 12.5 a 39 39 0 0 0 2.5 8.1 C 353.6 744.3 353.4 745.4 351.8 746 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb12"
                d="M 497.1 623.1 c -1.9 6.5 -7.1 9.3 -12.8 11.8 a 8.5 8.5 0 0 0 -3.7 1.1 h 0 c -0.9 0.1 -1.9 0.2 -2.9 0.3 a 28.2 28.2 0 0 1 -12.9 -2.4 a 1.7 1.7 0 0 1 -1.6 -0.6 a 12.6 12.6 0 0 1 -3.7 -1.6 l -0.8 -0.4 c -2.4 -0.4 -4.2 -2.2 -6.5 -2.7 c -6.3 -5.4 -12.5 -10.9 -15.2 -19.2 c -2.2 -6.9 -0.6 -13 5.2 -17.5 a 15.7 15.7 0 0 1 5.9 -3.2 c 3 -1.4 6.2 -1.2 9.3 -1.6 l 0.3 0 L 458 587 l 1.1 0 h 1 l 4.8 0.1 c 3.4 1.2 7.1 1.9 10.2 4 c 3.7 1.1 7 3.1 10.4 4.8 a 22.1 22.1 0 0 1 12.2 15.8 l -0.1 0 h 0 l 0.6 0.5 C 499.7 616 499.1 619.6 497.1 623.1 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt9"
                d="M 404.5 127.2 a 9.9 9.9 0 0 1 -2.4 3.7 c -2.5 2.3 -4.7 4.9 -7 7.4 c -0.3 0.6 -0.6 1.1 -1.3 1.3 a 1 1 0 0 1 -1 0.6 a 2.6 2.6 0 0 1 -1.5 1.2 h 0 l -3.8 3.1 c -2.3 1.1 -4.2 2.9 -6.3 4.4 a 8.9 8.9 0 0 0 -2.9 1.7 l 0 0 c -2.7 0.7 -5 2.4 -7.8 2.8 a 12.3 12.3 0 0 1 -10.2 -2.7 l 0 0 c -2.3 -2.5 -4.9 -4.6 -6.4 -7.7 a 10 10 0 0 1 -1.9 -6.3 A 83.6 83.6 0 0 1 357.2 106 a 4.6 4.6 0 0 1 1.9 -3.1 a 0.9 0.9 0 0 1 1.1 -0.3 h 1.1 a 0.3 0.3 0 0 1 -0.1 -0.3 c 5.6 0.7 11.3 1.3 16.4 4.2 l 1.5 0.6 l 2.4 0.7 l 0.9 0.8 a 0.8 0.8 0 0 1 0.4 0.1 l 0.7 0.3 v 0 l 1.6 0.7 l 11.8 6 c 2.2 2 4.3 4.1 6.5 6 C 405.3 123.3 405.3 125.1 404.5 127.2 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="d"
                d="M 142.3 307.6 c -0.2 0.7 -0.3 1.3 -0.5 2 s -0.3 1.1 -0.5 1.6 l 0 0.1 c 0 0.2 -0.1 0.3 -0.1 0.5 c -3.8 9.1 -10.7 14.2 -20.3 15.8 l -1.6 0.1 c -7.3 0.6 -14.5 0.2 -21.3 -2.6 L 97.1 325 a 3.9 3.9 0 0 1 -2.4 -0.9 l -3.9 -1.2 c -6.8 -3.3 -12.4 -7.8 -16 -14.6 c -0.5 -0.9 -0.9 -1.9 -1.4 -2.8 a 0.5 0.5 0 0 0 -0.1 -0.4 l -0.5 -1.2 c -1.7 -6.2 -0.2 -11.9 2.4 -17.5 a 1.6 1.6 0 0 1 0.7 -0.8 c 4.8 -2 4.3 -6.5 4.9 -10.5 c 0.3 -1.9 0 -3.9 0.9 -5.6 c 0.2 -0.6 0.5 -1.3 0.7 -1.9 c 0.1 -0.2 0.2 -0.4 0.4 -0.6 c 3.9 -6.7 10.2 -9.8 17.5 -11.1 c 5 -0.9 9.7 0.6 14.3 2.6 c 10.3 4.5 19.3 10.6 25.3 20.4 c 0.5 0.7 0.8 1.5 1.2 2.3 l 0.6 1.5 l 0.2 0.5 a 3.4 3.4 0 0 1 0.8 2.3 a 2 2 0 0 1 0.5 1.5 C 145.4 294.1 144.7 300.9 142.3 307.6 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt6"
                d="M 239.6 131.8 l -0.3 4.3 l -0.1 1.1 c -0.1 0.3 -0.1 0.7 -0.2 1 h 0 c -0.3 0.7 -0.6 1.4 -0.9 2.2 c 0 0.3 -0.1 0.7 -0.1 1 a 37.3 37.3 0 0 1 -4.8 6.5 c -3 3.6 -6.9 4.5 -11.1 3.5 c -9 -2.1 -16.4 -7 -23 -13.3 l -0.3 -0.4 l -0.3 -0.2 l -1.1 -1.2 l 0.1 0.1 l -0.6 -0.6 l -0.5 -0.5 l 0.1 0.1 l -0.5 -0.5 c -0.2 -0.2 -0.4 -0.4 -0.6 -0.6 v 0.1 c -1.1 -2.8 -3.8 -4.4 -4.8 -7.2 c -0.5 -1.5 -1.6 -5 0.2 -6.4 c 2.4 -2.9 5.6 -4.7 8.7 -6.6 A 77.1 77.1 0 0 1 232 102.5 c 0.9 -0.1 1.9 -0.5 2.6 0.5 a 0.5 0.5 0 0 0 0.2 0.5 c 0.1 0.4 0.2 0.7 0.3 1.1 a 3.8 3.8 0 0 0 0.5 3.1 l 0 0.5 a 1.3 1.3 0 0 0 0.3 1.2 a 2.2 2.2 0 0 1 0.5 2 a 0.4 0.4 0 0 1 0.1 0.4 l 0.5 0.7 l 2.1 12 c 0 0.2 0 0.4 0.1 0.6 a 12.6 12.6 0 0 0 0.3 4.2 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb13"
                d="M 518 559.8 a 6.5 6.5 0 0 1 -1.5 1.4 a 18.5 18.5 0 0 0 -3.3 10.9 c 0.1 3.3 -1.7 6.3 -3.5 9.1 c -8 8.9 -17.8 11.4 -27.5 7 c 0 -0.5 -0.2 -0.2 -0.4 -0.1 l -1.7 -0.4 a 33.7 33.7 0 0 1 -6.7 -2.1 a 100.3 100.3 0 0 0 -9 -6.6 c -4.8 -3.4 -7.6 -8.6 -11.2 -13 a 12.1 12.1 0 0 1 -2.8 -6.9 a 43.9 43.9 0 0 1 2.1 -24.6 a 9 9 0 0 1 1.2 -3.3 l 0.8 -1.5 c 2.5 -4.9 6.7 -7.8 11.6 -9.8 c 1.7 -0.7 3.4 -1.2 5.1 -1.8 c 8.3 -2.2 16.3 -0.4 24.3 1.6 c 4.7 1.1 8.5 4 13 5.5 c 4.3 1.8 6.5 5.6 8.5 9.4 l 0.3 0.4 a 0.5 0.5 0 0 1 0.2 0.3 l 0.1 0.1 a 0.6 0.6 0 0 1 0.4 0.3 a 0.4 0.4 0 0 0 0.2 0.2 a 0.4 0.4 0 0 1 0.3 0.3 c 0.3 0.7 1.2 0.9 1.2 1.8 C 523.6 545.7 522.1 552.9 518 559.8 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id={"tt1"}
                d="M 129.1 373.2 a 9.9 9.9 0 0 1 -1.6 3.8 a 38.2 38.2 0 0 1 -16.3 16.8 a 9.7 9.7 0 0 1 -4.8 0.9 c -14.7 -0.3 -28.5 -3.4 -41.2 -11.1 c -4.2 -2.5 -4.7 -6.8 -5.3 -10.9 s -0.3 -8 -0.4 -12 a 46.2 46.2 0 0 1 -0.2 -7.7 c 0 -0.3 0 -0.6 -0.1 -0.9 c -0.3 -3.8 -0.6 -7.6 -1 -11.3 a 7 7 0 0 1 2.4 -6.5 c 2 -1.8 3.8 -3.8 6.5 -4.8 a 3.4 3.4 0 0 0 2.2 -0.9 l 0.1 0 a 9.4 9.4 0 0 0 4.1 -1 h 0.1 c 1.6 0.1 3.1 0.1 4.7 0.4 c 8.6 1.2 17.1 2.5 25.7 3.8 l 0.1 0.1 l 0.7 0.3 l 3 0.6 a 65 65 0 0 1 12.1 6.5 a 12.8 12.8 0 0 1 5.3 6.3 a 21.2 21.2 0 0 0 2.6 3.9 v 0 l 0.7 1.2 a 4.5 4.5 0 0 1 1.4 2.7 C 130.2 360.1 130.5 366.7 129.1 373.2 Z"
                transform="matrix(1,0,0,1,-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt13"
                d="M 521.3 306.7 c -0.7 1.2 -1.5 2.3 -2.3 3.5 l 0 0 a 2.9 2.9 0 0 1 -1.1 1.6 c -3.3 5.3 -8.1 8.7 -13.7 11 l -0.2 0.1 l -1.1 0.6 A 41.7 41.7 0 0 1 485.6 328 a 89 89 0 0 1 -17.3 -1 a 12 12 0 0 1 -6.1 -3.3 c -5.1 -4.5 -8.1 -10.2 -9.9 -16.6 c -0.2 -0.5 -0.3 -1.1 -0.5 -1.6 a 41.6 41.6 0 0 1 -0.1 -17.4 A 41.3 41.3 0 0 1 458.8 275 c 1.1 -2 2.9 -3.4 4.5 -5 a 18.3 18.3 0 0 0 3.3 -3.9 c 2.5 -0.3 3.9 -2.4 5.9 -3.5 a 78.3 78.3 0 0 1 14.8 -6.1 c 3.1 -1.3 6.4 -0.8 9.6 -0.5 l 1 1.1 c 4.5 0.5 7.9 3.1 11.2 5.9 l 0.8 0.5 a 0.2 0.2 0 0 1 0.2 0.2 a 9.7 9.7 0 0 1 4 7.8 c 0 2.1 0.1 4.2 0.2 6.3 c 0.2 3.1 1.5 5.3 3.8 7.7 C 522.9 290.4 525.9 298.7 521.3 306.7 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb1"
                d="M 122.9 467.1 C 122.1 464.3 120.3 461.9 117.7 460.6 C 116.1 458.8 114.3 457.3 112.2 456.1 C 111.5 455 110.4 454.3 109.2 453.9 l 0 0 c -3.6 -2.2 -7.6 -2.3 -11.7 -1.9 C 93 452.4 88.5 453.3 84.2 454.7 c -6.6 2 -13.5 3.1 -19.6 6.4 c -4.3 2.3 -7 5.7 -7 10.8 C 57.8 473.1 57.6 474.4 57.2 475.5 c 0 0.4 -0.1 0.7 -0.1 1.1 c -0.2 1.5 -0.5 2.9 -0.5 4.4 c -0.4 7.9 0 15.9 -1.5 23.8 C 54.8 506.2 54.9 507.6 55.4 508.9 c 0.3 2.4 1.9 3.9 3.7 5.2 C 63.1 517 67.6 519 72.4 520.2 c 7.2 0.5 13.9 -1.4 20.6 -3.5 l 0.1 0.1 C 94 516.9 94.9 516.6 95.7 516.1 l 0.9 -0.2 l 1.7 -0.3 c 3.2 -0.8 6.4 -1.7 9.6 -2.4 C 111.2 512.5 114.1 510.8 116.5 508.5 c 3.3 -3.1 6.3 -6.4 7.6 -10.9 c 0.1 -0.4 0.5 -1.8 0.7 -2.4 C 127.4 485.5 125.9 476.2 122.9 467.1 Z M 84 517 h 0 Z"
                transform="matrix(1,0,0,1,-30.6,-63)" />
            <path id="line2"
                d="M 418.6 426 c -1.2 -1 -3.2 -0.2 -4.2 -1.8 c 2.1 0.1 2.9 0.1 4.2 0.1 c 7.2 0 14.1 -0.5 21.3 -0.4 a 11.9 11.9 0 0 1 6 1.2 c -0.7 1 -1.4 0.8 -2.1 0.8 c -7.4 0.2 -14.8 -0.7 -22.3 0.1 A 18.4 18.4 0 0 0 418.6 426 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb2"
                d="M 137 567 c -5.1 8.3 -12.4 14.1 -21 18.3 c -7.3 3.6 -14.9 4 -22.8 2.2 c -3.7 -0.9 -6.1 -3.6 -8.9 -5.8 c -3.2 -2.5 -4 -5.7 -4.1 -9.4 c -0.1 -2.1 -0.4 -4.2 -0.6 -6.3 a 4.6 4.6 0 0 0 -1.3 -3.1 c -2 -2.1 -3.5 -4.5 -5.3 -6.8 a 12.7 12.7 0 0 1 -2.7 -7.5 a 15.4 15.4 0 0 1 1.1 -8.4 a 12.1 12.1 0 0 1 2.4 -4.3 l 0 -0.1 l 6.4 -8.1 c 2 -2.4 5 -3.1 7.6 -4.5 a 52.2 52.2 0 0 1 30 -5.8 c 2.2 0.2 4.3 0.6 6.5 0.8 c 6.2 1.3 10.5 5.1 13.6 10.4 C 144.2 538 145.2 558.1 137 567 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt5"
                d="M 203.5 171.7 a 4.5 4.5 0 0 1 -0.7 2.3 c -0.1 0.2 -0.2 0.4 -0.3 0.5 l 0 0 v 0 c -0.2 0.2 -0.3 0.3 -0.5 0.5 l 0 0 l 0 0 l -0.5 0.5 a 6.3 6.3 0 0 1 -2.6 1.6 l -1.7 1 a 6.1 6.1 0 0 1 -2.5 1.1 c -3 1 -6 0.4 -9 -0.2 c -4.4 -1 -8.8 -2 -13.2 -3.1 c -4.8 -1.2 -8.5 -4.3 -12.3 -7.3 l -0.8 -0.5 a 3.2 3.2 0 0 1 -1.9 -1.5 c -2.6 -2.6 -3.1 -6.3 -4.6 -9.5 a 11.7 11.7 0 0 1 -0.4 -9.4 c 2.3 -6 5.7 -11.2 11.6 -14.3 c 5.5 -2.9 12.7 -4 18.8 -4.9 c 1.9 -0.3 6.8 1.1 7.5 3.5 l 3.4 5 a 35.9 35.9 0 0 1 4.5 10.7 c 0.6 2.3 1 4.6 1.4 6.9 c 0.4 1 0.9 2.1 1.3 3.1 a 3.8 3.8 0 0 1 0.9 2.6 l 0.4 1.1 C 203.4 164.7 204.4 168.1 203.5 171.7 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt7"
                d="M 282.1 89.6 c 3.4 0.4 6.8 0.9 10.2 1.2 c 1.8 0.2 2.6 0.9 2.6 2.8 c 0.1 8.1 -1.5 16 -3.5 23.8 c -0.8 3.2 -1.9 6.4 -2.9 9.5 c -2.7 9 -9.3 12.7 -17.6 13.6 c -2.3 0.3 -4.7 -0.9 -6.6 -2.2 c -5.4 -3.7 -10.3 -7.8 -13.7 -13.7 c -2.4 -4.2 -5.7 -8 -7.9 -12.4 a 82.6 82.6 0 0 1 -3.6 -9.3 c -0.7 -1.8 0 -2.8 1.7 -3.8 a 75.3 75.3 0 0 1 30.8 -9.7 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt11"
                d="M 471.6 195.4 c -0.5 4.4 -3.8 7.2 -6.5 10.2 a 51.8 51.8 0 0 1 -5 4.2 a 80 80 0 0 0 -10.9 5.6 c -3.6 2 -7.3 3.4 -11.4 3.2 c -3 -0.1 -6.1 -0.3 -9.2 -0.4 a 17.6 17.6 0 0 1 -5.2 -2.7 c -1.9 -2.2 -4.3 -4 -5.3 -6.8 a 15.5 15.5 0 0 1 0 -8.9 c 0.3 -1.8 0.7 -3.5 1 -5.3 l 1.3 -4 l 0 -0.1 l 1.2 -1.9 c 0.3 -0.7 0.6 -1.4 0.9 -2.1 l 0.1 -0.1 a 8.1 8.1 0 0 0 2 -2.9 l 0.2 -0.2 l 0.4 -0.4 l 0.2 -0.1 l 0.4 -0.5 l 0.1 -0.1 c 2.5 -2.4 4.4 -5.5 7.7 -7 a 1.4 1.4 0 0 0 0.4 -0.1 c 1.6 0 2.8 -0.9 4 -1.7 c 4.1 -2.6 8.4 -4.3 13.3 -3.6 c 5.6 0.8 10.3 3.7 14.9 6.8 c 3.2 2.2 4.8 5.5 5.4 9.2 A 33.7 33.7 0 0 1 471.6 195.4 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tt12"
                d="M 497.1 238.9 l -0.4 0.9 a 0.7 0.7 0 0 0 -0.3 0.4 c -2 4.3 -5.4 7.5 -9 10.5 c -2.6 1.5 -4.8 3.7 -8.1 3.8 l -0.8 0.4 l -0.1 0.1 a 41.5 41.5 0 0 1 -17.2 4.3 l -0.1 0.1 l -0.1 -0.1 a 11.2 11.2 0 0 1 -5 1.1 v 0 a 8.9 8.9 0 0 1 -6.5 -0.2 l -0.9 -0.3 a 2.7 2.7 0 0 1 -1.8 -0.5 l -0.2 0 l -0.1 -0.1 a 5.3 5.3 0 0 1 -2.6 -1 l -0.7 -0.4 l -2 -1 a 10.2 10.2 0 0 0 -2.8 -2.8 c -4.4 -3.5 -5.6 -8 -3.9 -13.3 a 21.4 21.4 0 0 1 3.5 -7.2 l 0 -0.1 l 0.4 -0.5 l 0.6 -0.8 c 0 -0.2 0.1 -0.4 0.1 -0.6 a 3.2 3.2 0 0 0 0.6 -0.6 c 5.8 -9.3 14.5 -14.7 24.3 -18.7 c 5.5 -2.2 11.4 -1.5 17.2 -1.4 c 6.9 0.9 11.6 4.9 14.8 10.8 a 3.6 3.6 0 0 1 1 2.2 C 499.3 228.9 499.9 233.9 497.1 238.9 Z"
                transform="translate(-30.6,-63)" />

            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb9"
                d="M 403.8 722.9 c -0.8 1 -1.4 2.2 -2.1 3.3 l -5.6 4 l -0.7 0.5 a 6.7 6.7 0 0 1 -2.6 2 c -8 7 -17.9 9.1 -28 10.7 c -2 0.3 -4 1.4 -6.1 0.4 c -2.6 -2.6 -2.8 -6.2 -3.8 -9.4 c 0 -0.3 0 -0.6 0 -1 a 52.8 52.8 0 0 1 -2.8 -21.7 a 7.8 7.8 0 0 1 1.3 -4.2 c 1.3 -3.5 2.8 -6.9 5.8 -9.4 c 1.5 -1.3 3.4 -1.5 5.2 -2 l 0.5 -0.1 l 0.5 -0.1 l 1 -0.1 a 3.4 3.4 0 0 1 2.3 0 a 13.5 13.5 0 0 1 7.2 1.3 c 7.8 1.9 12 9.3 18.9 12.7 a 9.5 9.5 0 0 1 2.1 2 l 0.5 0.6 c 1.1 0.6 1.3 1.8 2.3 2.6 a 16.6 16.6 0 0 0 1.3 2 c 0.9 1.4 1.8 2.7 2.7 4.1 A 1.4 1.4 0 0 1 403.8 722.9 Z"
                transform="translate(-30.6,-63)" />
            <path ref={ref} onClick={handleClick}
                onDoubleClick={handleDoubClick} id="tb5"
                d="M 199.9 676.1 c -0.7 9.8 -1.4 19.5 -5.9 28.5 c -0.6 1.2 -1 2.6 -2.2 3.5 v 0 h 0 c -0.2 0.4 -0.5 0.8 -0.7 1.1 a 0.4 0.4 0 0 1 -0.2 0.3 a 6 6 0 0 1 -2.4 3.5 a 0.2 0.2 0 0 1 -0.2 0.2 L 187 714.5 l -0.5 0.3 a 6.2 6.2 0 0 1 -3.5 1 a 34.5 34.5 0 0 1 -20.6 -4.6 a 27.5 27.5 0 0 1 -11.2 -12.2 c -0.2 -0.5 -0.3 -1 -0.5 -1.6 s -0.3 -1.1 -0.5 -1.6 a 0.4 0.4 0 0 0 -0.1 -0.3 c -0.1 -0.4 -0.2 -0.8 -0.3 -1.2 a 14.1 14.1 0 0 1 2.8 -10.8 a 48.3 48.3 0 0 0 2.8 -4.4 l 0 -0.1 l 0.4 -0.6 h 0 a 4.6 4.6 0 0 1 2.7 -2.7 c 0.2 -0.1 0.4 -0.3 0.6 -0.5 s 0.4 -0.2 0.6 -0.4 l 0.1 0 c 9.6 -5.3 19.7 -8.5 30.7 -8.4 a 6.3 6.3 0 0 1 4.4 1.5 l 0.1 0.1 l 0.4 0.4 c 2.3 0.8 3 2.8 3.8 4.8 A 3.3 3.3 0 0 1 199.9 676.1 Z"
                transform="translate(-30.6,-63)" />

            <polygon points="53.3,454 53.3,454 53.3,454 53.3,454" /><text class="cls-11"
                transform="translate(355.9,35.4)">9</text><text class="cls-11" transform="translate(409.6,66)">10</text><text
                    class="cls-11" transform="translate(447.8,112.8)">11</text><text class="cls-11"
                        transform="translate(478.4,160.6)">12</text><text class="cls-11" transform="translate(499,218.3)">13</text><text
                            class="cls-11" transform="translate(521.5,287.2)">14</text><text class="cls-11"
                                transform="translate(363.2,695.4)">9</text><text class="cls-11"
                                    transform="translate(420.5,656.5)">10</text><text class="cls-11"
                                        transform="translate(453.8,612.1)">11</text><text class="cls-11"
                                            transform="translate(485.4,560.1)">12</text><text class="cls-11"
                                                transform="translate(505.2,509.3)">13</text><text class="cls-11" transform="translate(523.2,434)">14</text><text
                                                    class="cls-11" transform="translate(3.1,285.5)">1</text><text class="cls-11"
                                                        transform="translate(17.2,216.7)">2</text><text class="cls-11" transform="translate(39.4,149.9)">3</text><text
                                                            class="cls-11" transform="translate(74.7,100.3)">4</text><text class="cls-11"
                                                                transform="translate(114.4,60.4)">5</text><text class="cls-11" transform="translate(164.1,31.9)">6</text><text
                                                                    class="cls-11" transform="translate(225,15.8)">7</text><text class="cls-11"
                                                                        transform="translate(290.1,15.8)">8</text><text class="cls-11" transform="translate(0,432.5)">1</text><text
                                                                            class="cls-11" transform="translate(14.7,509.3)">2</text><text class="cls-11"
                                                                                transform="translate(40.3,576.7)">3</text><text class="cls-11" transform="translate(65.1,619.8)">4</text><text
                                                                                    class="cls-11" transform="translate(105.6,667.6)">5</text><text class="cls-11"
                                                                                        transform="translate(162,700.8)">6</text><text class="cls-11" transform="translate(225,719.7)">7</text><text
                                                                                            class="cls-12" transform="translate(298.9,720) scale(0.9,1)">8</text>
        </svg>
    )
}

export default Teeth;