import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import { Doctor } from "./Doctor/Doctor";
import { PatientInfo } from "./Doctor/patient/PatientInfo";
import { Procedures } from "./Doctor/procedures/Procedures";
import { Session } from "./Doctor/patient/Session";
import { PatientAdd } from "./Doctor/patient/patientAdd";
import Home from "./Home/Home";
import Admin from "./Admin/Home";
import "./index.css";

import RequireAuth from './actions/RequireAuth';
import Login from "./Login/Login";
function App() {
  const ROLES = {
    'superadmin': 'superadmin' ,
    'admin':'admin',
    'doctor': 'doctor',
    'rec':'rec'
  }

  return (
  
      <Routes>
             <Route path="/" element={<Login />} />
            <Route element={<RequireAuth allowedRoles={[ROLES.superadmin]} />}>
        <Route path="/Admin" element={<Admin />} />

        </Route>

   
        <Route element={<RequireAuth allowedRoles={[ROLES.rec]} />}>
        <Route path="/Home" element={<Home />} />
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.doctor]} />}>
        <Route path="/Doctor" element={<Doctor />} />
        <Route
          path="/Doctor/Session/:type"
          element={<Session />}
          handler={"New Session"}
        />
        <Route
          path="/Doctor/Session"
          element={<Session />}
          handler={"New Session"}
        />
        <Route
          path="/Doctor/Session/:type/PatientInfo"
          element={<PatientInfo />}
          handler={"New Session"}
        />
        <Route path="/Doctor/Procedures" element={<Procedures />} />
        <Route path="/Doctor/Session/Session/:id" element={<PatientAdd />} />
        <Route path="/Doctor/Session/MyPatient/:id" element={<PatientAdd />} />
        </Route>
      
      
        
         
       
        
        <Route path="*" element={<h1>page 404</h1>} />
      </Routes>
 
  );
}

export default App;
