import React, { useState, useEffect } from "react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "./create.css";
import { CreatePatient } from "../../Models/model";
import Swal from "sweetalert2";
import $ from "jquery";
import api from "../../api/index";
import { Button, Input } from "@mui/material";

export const Create = ({}) => {
  const [Room, setRoom] = useState([]);
  const [Doctor, setDoctor] = useState([]);
  const [Patient, setPatient] = useState([]);

  const [state, setState] = useState({
    isPatientName: "",
    isDoctorName: "",
    isRoom: "",
    recipientnote: "",
  });

  const handleChange = (e) => {
    var value = $("#" + e.target.name).val();
    var uuidvalue;
    //TODO ما عندي ما ندير
    if (e.target.name == "isRoom") {
      uuidvalue = $('#Roomlist [value="' + value + '"]').data("value");
    }
    if (e.target.name == "isDoctorName") {
      uuidvalue = $('#doctorlist [value="' + value + '"]').data("value");
    }
    if (e.target.name == "isPatientName") {
      uuidvalue = $('#Patientlist [value="' + value + '"]').data("value");
    }
    if (e.target.name == "recipientnote") {
      uuidvalue = e.target.value;
    }

    if (uuidvalue == undefined) {
      console.log(uuidvalue);
    } else {
      setState({
        ...state,
        [e.target.name]: uuidvalue,
      });
    }
  };

  function Add() {
    (async () => {
      var ismale = false;
      const { value: formValues } = await Swal.fire({
        title: "معلومات المريض",
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> اسم المريض  </label><br/><input id="name" class="Text-end" style="width:94%;padding:5px" required>  </div>' +
          "</div>" +
          '<div class="Box" style="justify-content: space-around;padding-top:10px">' +
          '<div class="Box" style="justify-content: space-around;width:100%">' +
          '<div style="width:100%"><label class="down"> العنوان </label><br/><input id="address" class="Text-end" style="width:90%;padding:5px" required>  </div>' +
          '<div style="width:100%"><label class="down"> تاريخ الميلاد</label><br/><input id="birthday" class="Text-end" type="date" style="width:90%;padding:5px" required>  </div>' +
          "</div>" +
          "</div>" +
          '<div class="Box" style="justify-content: space-around;width:100%">' +
          '<div style="width:100%"><label class="down">رقم الهاتف</label><br/><input id="phone_number" type="number" class="Text-end" style="width:90%;padding:5px" required>  </div>' +
          '<div style="width:100%"><label class="down">جنس</label><br/> <select class="Text-end" style="height:35px;width:90%;padding:5px"  id="sex"><option value="false">أنثي</option><option value="true">ذكر</option></select>  </div>' +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>",
        width: 1000,

        preConfirm: () => {
          console.log(ismale);
          return [
            document.getElementById("name").value,
            document.getElementById("address").value,
            document.getElementById("birthday").value,
            document.getElementById("phone_number").value,
            document.getElementById("sex").value,
          ];
        },
      });

      if (formValues) {
        const json = Swal.fire(JSON.stringify(formValues));

        setState({
          ...state,
          isPatientName: formValues[0],
        });

        CreatePatient(formValues);
      }
    })();
  }
  //handleSubmit data to api
  const handleSubmit = (event) => {
    event.preventDefault();

    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (
      state.isPatientName != "" &&
      state.isDoctorName != "" &&
      state.isRoom != ""
    ) {
     
      api
      .post(
        `/session`,
        {
          patient: state.isPatientName,
          doctor: state.isDoctorName,
          room: state.isRoom,
          recipientnote: state.recipientnote,
        },
        { headers: { Authorization: `Bearer ${tokens["access_token"]}` } }
      ).then(() =>          Swal.fire({
        icon: "success",
        title: "success...",
        text: "session add it",
      }).then(() => window.location.reload()))
      .catch(function(error) {

        console.log(error.response.data);
        if (error.response) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error + "!",
          });
          return 0;
        } else {
          

        }
      });
    }else{
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "يرجي تأكد من بيانات!",
      });
    }
 
  };

  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    const getPateint = () => {
      api
        .get("/patients", {
          headers: {
            Authorization: `Bearer ${tokens["access_token"]}`,
          },
        })
        .then((data) => data["data"])
        .then((data) => setPatient(data));
    };
    const getRooms = () => {
      api
        .get("/rooms", {
          headers: {
            Authorization: `Bearer ${tokens["access_token"]}`,
          },
        })
        .then((data) => data["data"])
        .then((data) => setRoom(data));
    };

    const getDoctors = () => {
      api
        .get("/doctors", {
          headers: {
            Authorization: `Bearer ${tokens["access_token"]}`,
          },
        })
        .then((data) => data["data"])
        .then((data) => setDoctor(data));
    };
    getPateint();
    getRooms();
    getDoctors();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className="" style={{ padding: "20px" }}>
        <div className="Box">
          <AddCircleIcon className="down" onClick={() => Add()} />
          <input
            list="Patientlist"
            id="isPatientName"
            name="isPatientName"
            style={{
              width: "200px",
              height: "30px",
              borderRadius: "25px",
              margin: "1%",
              border: "1px #2699FB solid",
              textAlign: "start",
              paddingRight: "2%",
            }}
            onChange={handleChange}
          />

          <datalist id="Patientlist">
            {Patient.map((Patient, index) => (
              <option
                key={Patient.uuid}
                value={Patient.name}
                data-value={Patient.uuid}
              >
                {" "}
              </option>
            ))}
          </datalist>

          <label className="down">اسم المريض</label>
        </div>

        <div className="Box">
          {/* <ArrowDropDownCircleIcon className="down" /> */}
          <input
            list="doctorlist"
            id="isDoctorName"
            name="isDoctorName"
            style={{
              width: "200px",
              height: "30px",
              borderRadius: "25px",
              margin: "1%",
              border: "1px #2699FB solid",
              textAlign: "start",
              paddingRight: "2%",
            }}
            onChange={handleChange}
          />
          <datalist id="doctorlist">
            {Doctor.map((Doctor) => (
              <option
                key={Doctor.uuid}
                value={Doctor.name}
                data-value={Doctor.uuid}
              ></option>
            ))}
          </datalist>

          <label className="down">اسم الدكتور</label>
        </div>

        <div className="Box">
          <input
            list="Roomlist"
            id="isRoom"
            name="isRoom"
            style={{
              width: "200px",
              height: "30px",
              borderRadius: "25px",
              margin: "1%",
              border: "1px #2699FB solid",
              textAlign: "start",
              paddingRight: "2%",
            }}
            onChange={handleChange}
          />
          <datalist id="Roomlist">
            {Room.map((Room, index) => (
              <option
                key={Room.uuid}
                value={Room.code}
                data-value={Room.uuid}
              ></option>
            ))}
          </datalist>

          <label className="down">كود الغرفه</label>
        </div>

        <div className="Box">
          <input
            id="recipientnote"
            name="recipientnote"
            style={{
              width: "500px",
              height: "90px",
              borderRadius: "25px",
              margin: "1%",
              border: "1px #2699FB solid",
              textAlign: "start",
              paddingRight: "2%",
            }}
            onChange={handleChange}
          />

          <label className="down">الملاحظات</label>
        </div>
        <br></br>

        <div className="Box">
          <Button
            variant="contained"
            className="Save-Button"
            style={{
              fontFamily: "Tajawal",
              width: "400px",
              textAlign: "end",
              margin: "2%",
            }}
            type="submit"
          >
            حفظ
          </Button>
        </div>
      </div>
    </form>
  );
};
