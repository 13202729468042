import  React , { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import PersonIcon from '@mui/icons-material/Person';
import './Table.css';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { Active,DisActive } from "../Models/model"
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Swal from "sweetalert2";
import api from "../api/index";
//handleSubmit data to api
function handleSubmi  (formValues)  {
   
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  api.post(`/treatment`, {
    name: formValues[0],
    code: formValues[1],
    price:  parseFloat(formValues[2]) 

  },
    { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
      console.log(error.response.data)
      if (error.response.data) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error.status + '!',

        })
        return 0;

      }

    })
  Swal.fire({
    icon: 'success',
    title: 'success...',
    text: 'Treatment add it',

  }).then(() =>
  window.location.reload()
  );
  
}
//add pop up
function Add() {
  (async () => {
       
     
    const { value: formValues } = await Swal.fire({
      showCancelButton: true,
      title: "معلومات الخدمة",
      html:
        '<div class="block" style="direction:ltr;">' +
        '<div class="Box" style="justify-content: space-around;">' +
        '<div style="width:100%"><label class="down"> كود  </label><br/><input   name="code" id="code"  placeholder="اسم الغرفة" class="Text-end" style="width:94%;padding:5px" required>  </div>' +
        '<div style="width:100%"><label class="down"> اسم  </label><br/><input   name="name" id="name"  placeholder="اسم"  class="Text-end" style="width:94%;padding:5px" required>  </div>' +

        "</div>" +
       ' <div class="Box" style="justify-content: space-around;">' +
        '<div style="width:100%"><label class="down"> سعر  </label><br/><input     name= "price"  id="price"   class="Text-end" style="width:94%;padding:5px" required>  </div>' +
      
        "</div>" +
        "</div>" +

        "</div>",
      width: 1000,

      preConfirm: () => {
      
       
        return [
          document.getElementById("code").value,
          document.getElementById("name").value,
          document.getElementById("price").value,
        ];
      },
    }
    );

    if (formValues) {
      const json = Swal.fire(JSON.stringify(formValues));

 
console.log(formValues)
handleSubmi(formValues)
    }
  })();
}
      //edit datatable 
      const handleEdit = params => {
        (async () => {
         
          const code =params.row.code
          console.log(code)
          const status =params.row.status
          const name =params.row.name
          const balance =params.row.balance
          const { value: formValues } = await Swal.fire({
            showCancelButton: true,
            title: "معلومات غرفة",
            html:
              '<div class="block" style="direction:ltr;">' +
              '<div class="Box" style="justify-content: space-around;">' +
              '<div style="width:100%"><label class="down"> code</label><br/><input id="code1" class="Text-end"  type="text" style="width:90%;padding:5px" value="'+ code +'" >  </div>' +
              "</div>" +
              '<div class="Box" style="justify-content: space-around;">' +
              '<div style="width:100%"><label class="down"> name </label><br/><input id="name1" class="Text-end"  type="text" style="width:90%;padding:5px" value="'+name+'" >  </div>' +
              '<div style="width:100%"><label class="down"> balance </label><br/><input id="price" class="Text-end" style="width:90%;padding:5px" value="'+balance+'">  </div>' +
              "</div>" +
              "</div>" +
              "</div>",
            width: 1000,
          
            preConfirm: () => {
              
              return [
                //req API
                update(code, document.getElementById("code1").value, document.getElementById("name1").value, document.getElementById("price").value,)
              ];
              
            },
          
            
          }
          );
              //edit API
    function update(code,Newcode,name,price) {
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      console.log(code,Newcode,name,price)
      api.put(`/treatment?code=`+code , {
        code: Newcode,
        name: name,
        price :  parseFloat(price) 
      },
        { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
          if (error.response.data) {
          
            console.log(error.response.data)
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: error.response.data.error + '!',
    
            })
            return 0;
    
          }
    
        })
      Swal.fire({
        icon: 'success',
        title: 'success...',
        text: 'Treatment update',
    
      }).then(() =>
      window.location.reload()
    );
    }
        
         
        })()
      };
const columns = [
  
  { field: 'name', headerName: 'الاسم ', width: 160 },
  { field: 'code', headerName: 'code ', width: 160 },
  { field: 'balance', headerName: 'السعر', width: 160 },
  { field: 'status', headerName: 'الحالة ', width: 160 },
  {
    field: "actionEdit",
    headerName: "تعديل",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
  
        handleEdit(params)
      };

      return <Button onClick={onClick}><ModeEditIcon /></Button>;
    },
  },

  {
    field: "diasvtice",
    headerName: "تنشيط",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        
        const tokens = JSON.parse(localStorage.getItem('tokens'));
        if(params.row.status=='Active'){
         
          Active(params,'treatment')
        }else{
          DisActive(params,'treatment')
        }
      };
if(params.row.status=='Active'){
  return <Button onClick={onClick}> <PersonIcon  style={{ color: '#ea0075' }}  /></Button>
}else{
  return <Button onClick={onClick}> <PersonOffIcon style={{ color: 'red' }}  /></Button>
}},}
];

export default function ServicesList() {
    //create object tableData for view data 
  const [tableData, setTableData] = useState([]);

  //handleChange of all input value


    
     //API object tableData for view data 
  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    api.get('/treatments', {
      headers: {
        'Authorization': `Bearer ${tokens["access_token"]}`
      }
    }).then((data) => data['data']).then((data) => setTableData(data));
  }, []);
     //get id
  const handleGetRowId = (e) => {
    return e.uuid
  }

  return (
    <div>
        <div className="flex" style={{ padding: "10px 20px", marginTop: "5px ", }}>
      
      <div className="">
      <AddCircleIcon className="flex" onClick={() => Add()} />
      </div>
      <div className="">
      <label className="down"  >
        
        {"إضافة "}
        
      </label>
  
      </div>
              
        
         
  
        </div>
   
      <div style={{ height: 770, width: '100%', background: 'white', direction: "rtl" }} className="table" >
        
      <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={12}
          getRowId={handleGetRowId}
         
          rowSelection='single'
          rowsPerPageOptions={[6]}
          

          className="DataGrid"
        />
      </div>
    </div>

  );
}
