import { useLocation, Navigate, Outlet } from "react-router-dom";
import React, { useState } from 'react'
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    console.log( localStorage.getItem('role'))
    if(  localStorage.getItem('role') == '"superadmin"' || '"admin"' ){
   
    
        return (
     
    
            <Outlet />
           
          
    );
    }else if( allowedRoles == 'doctor' && auth.roles == 'doctor'){


    return (
 

        <Outlet />
       
      
);
}else if( allowedRoles == 'rec' && auth.roles == 'rec'){


    return (
 

        <Outlet />
       
      
);
}else{
    return (
 

        <Navigate to="/" state={{ from: location }} replace />
       
      
);
    
}

    }


export default RequireAuth;