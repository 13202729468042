
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Header } from '../../Component/Header/Header';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Swal from "sweetalert2";


import api from "../../api/index";
import { useParams, useNavigate } from "react-router-dom";
import '../../Component/Table/Table.css'
export const Session = ({ title }) => {
  const navigate = useNavigate();
  const handleGetRowId = (e) => {
    return e.uuid
  }
  let columns = [];
  const [TableData, setTableData] = useState([]);
  const [treatments, settreatments] = useState([]);
  let { type } = useParams();
  if (type == 'Session') {
    useEffect(() => {
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      // something wrong here 
      api.get('session/doctor', {
        headers: {
          'Authorization': `Bearer ${tokens["access_token"]}`
        }
      }).then((data) => data['data']).then((data) => setTableData(data));
    }, []);
    columns = [

      { field: 'patientname', headerName: 'الاسم ', width: 160 },
      { field: 'created', headerName: ' جلسه', width: 260 },
      { field: 'room', headerName: ' غرفه', width: 260 },
      { field: 'recipientnote', headerName: 'recipientnote', width: 260 },
      {
        field: "actionEdit",
        headerName: "عرض",
        sortable: false,
        renderCell: (params) => {
          const onClick = (e) => {

            Swal.fire({
              title: 'Are you sure?',
              text: " ",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, open it!'
            }).then((result) => {
              if (result.isConfirmed) {
                console.log(params.row.status)
                if(params.row.status=='pending'){
                  console.log('aa')
                  const tokens = JSON.parse(localStorage.getItem('tokens'));

                  api.put(`/session?uuid=`+params.id, {
                    status:"open"
  
                  },
                    { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
                      console.log(error.response.data)
                      if (error.response.data) {
                        Swal.fire({
                          icon: 'error',
                          title: 'Oops...',
                          text: error.response.data.error + '!',
  
                        })
                        return 0;
  
                      }
  
                    })
                }
   
                navigate(params.id)
              }
            })
          };

          return <Button onClick={onClick}><VisibilityIcon /></Button>;
        },
      },
    ];

  }
  if (type == 'MyPatient') {
    useEffect(() => {
      const tokens = JSON.parse(localStorage.getItem('tokens'));
      api.get('/patients', {
        headers: {
          'Authorization': `Bearer ${tokens["access_token"]}`
        }
      }).then((data) => data['data']).then((data) => setTableData(data));

    }, []);
 
    columns = [

      { field: 'name', headerName: 'الاسم ', width: 160 },
      { field: 'last_session', headerName: 'اخر جلسه', width: 260 },
      {
        field: "actionEdit",
        headerName: "عرض",
        sortable: false,
        // renderCell: (params) => {
        //   const onClick = (e) => {
        //     navigate(params.id)

        //   };

        //   return <Button onClick={onClick}><VisibilityIcon /></Button>;
        // },
      },
    ];

  }


  return (
    <div className='Doctor_Patient_info'>
      <Header title={type} />

      <br>
      </br>
      <div style={{ display: 'flex', height: '100%', direction: "rtl" }}>
        <div className="DataGrid1">
          <DataGrid
            rows={TableData}
            columns={columns}
            pageSize={12}
            getRowId={handleGetRowId}

            rowSelection='single'
            rowsPerPageOptions={[6]}


          // /className="DataGrid"
          />
        </div>
      </div>

    </div>
  )
}
