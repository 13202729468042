import * as React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import './Table.css';
const columns = [
  { field: 'id', headerName: 'رقم', width: 70 },
  { field: 'name', headerName: 'اسم', width: 160 },
  { field: 'code', headerName: 'الكود ', width: 130 },
  {
    field: 'price',
    headerName: 'السعر ',
    width: 90,
  },
];

const rows = [
  { id: 1,  price: '100.00', code: '0.00', name: 'name' },
  { id: 2,  price: '100.00', code: '0.00', name: 'name' },
  { id: 3,  price: '100.00', code: '0.00', name: 'name' },
];

export default function DataTable() {
  return (
    <div>
      <div className="block" style={{ padding: "10px 20px", marginTop: "5px ",}}>
      </div>
      <div style={{ height: 770, width: '100%', background: 'white', direction: "rtl" }} className="table" >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[6]}
          checkboxSelection
          className="DataGrid"
        />
      </div>
    </div>

  );
}
