import React, { useState } from 'react'
import api from "../api/index";
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import useAuth from '../hooks/useAuth';

import {Routes, Route, useNavigate} from 'react-router-dom';
import { Link,  useLocation } from 'react-router-dom';

import './Login.css'


import Swal from "sweetalert2";


function Login()  {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setAuth } = useAuth();
  const from = "/aa";
    const navigate = useNavigate();
    const location = useLocation();
  

  
  function log(e) {
    localStorage.clear();
    e.preventDefault();
     try {
      api.post(`/login`, {
        username: username,
        password: password,
    },
    {headers: { 'content-type': 'application/json'} }).then(function (response) {
   
      const roles = response['data']['role'];
    
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Login success ',
        showConfirmButton: false,
        timer: 1500
      })
       // set the state of the user
    // store the user in localStorage

      localStorage.setItem('user',JSON.stringify(response.data) )
      localStorage.setItem('role',JSON.stringify(response.data["role"]) )
      localStorage.setItem('isSelected','AddSession') 
      const tokens= JSON.stringify(response.data["tokens"])
      localStorage.setItem('tokens',JSON.stringify(response.data["tokens"]) )
      setAuth({ username, password, roles, tokens });

      switch(roles){
        case "doctor":
          navigate("/Doctor")
          return;
        case "admin":
          navigate("/Admin" , { replace: true })
          return;
        case "superadmin":
          navigate("/Admin")
          return;
        case "rec":
          navigate("/Home")
          return;
        default:
          
        }
     
    }).catch(function (error) {
      if (error.response.data) {
   
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error + '!',
  
        })
       
        return 0;
  
      }
  
    })
     } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error + '!',

      })
     }


  }

  return (<>
    <div className='image-background'>
      <div className='top'>

      </div>
      <div className='Center'>
        <div className='img-conter' >

        </div>
        <div className='img-conter1'>
          <div className='top' >

          </div>
          <h2 className='Login-Header'>
            تسجيل الدخول
          </h2>

          <div className='Login-Center'>

            <form  onSubmit={log}>
              <FormControl className='Login-Form'>
              <label className='Login-label' >
                أسم المستخدم

              </label>
              <input required className='Login-Input' placeholder=""   id="username" onChange={(e) => setUsername(e.target.value)}

              />
              <br />
              <label className='Login-label' >
                كلمة المرور

              </label>
              <input required className='Login-Input' placeholder="  "  type="password" onChange={(e) => setPassword(e.target.value)}

              />
              <br />
              <br />
              <button  style={{backgroundColor:"#4666D1",border:"none", color:"#fff", borderRadius:"10px",padding:"20px", alignSelf: "stretch"}} >دخول</button>
              {/* <Link to={'/Home'}><Button1 text={'تسجيل الدخول'} className='Login-Button' />
              </Link>
              <Link to={'/Doctor'}><Button1 text={'تسجيل الدخول'} className='Login-Button' />
              </Link>
              <Link to={'/Admin'}><Button1 text={'تسجيل الدخول'} className='Login-Button' />
              </Link> */}
              {/* <Link to={''}><Button1 text={'تسجيل الدخول'} className='Login-Button' />
              </Link> */}
              </FormControl>
            </form>

          </div>
        </div>
      </div>
    </div>
  </>
  )
}
export default Login;


