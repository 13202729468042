import React from 'react'
import './Header.css'
export const Header = ({title}) => {

  return (
    <div className=''>
       <div className='Header0' >
        <br></br>    <br></br>
<div className="Header">
<h2 className='Text-Header'>

{title}
          </h2>
</div>
       </div>
    </div>
  )
}
