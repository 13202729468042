import React, { useEffect } from 'react'
import './Salder1.css'
import logo from "../../img/icon1.png"
export const Salder1 = ({ setIsAdding, isAdding }) => {

  useEffect(() => {


  });
  return (
    <div className='slader'>
      <div className='img'>
        <img src={logo} alt="" className='Logo-img1' />
      </div>
      <br></br>
      <div className="buttonitems">
        <button className={`${isAdding ? "Active" : "NONActive"}`} onClick={() => setIsAdding(true)} >إضافة جلسة</button><br></br>
        <button className={`${isAdding ? "NONActive" : "Active"}`} onClick={() => setIsAdding(false)}  >قائمة المرضي</button>
      </div>
    </div>
  )
}
