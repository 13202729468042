import  React , { useState, useEffect } from 'react';
import api from "../api/index";

import { DataGrid } from '@mui/x-data-grid';
import Swal from "sweetalert2";
import { CreateUser } from "../Models/model"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import './Table.css';
import { Button } from '@mui/material';
// TODO COLUMNS TABLE 
const columns = [
  { field: 'name', headerName: 'اسم دكتور', width: 200  },
  { field: 'username', headerName: 'username', width: 200 },
  { field: 'phone_number', headerName: 'رقم الهاتف ', width: 130 },
  { field: 'birthday', headerName: 'تاريخ الميلاد ', width: 250 },
  { field: 'isinstanced', headerName: ' isinstanced ', width: 130 },
  { field: 'status', headerName: ' status ', width: 130 },
  {
    field: 'balance',
    headerName: 'الرصيد',
    width: 100,
  },

  { field: 'role', headerName: 'role', width: 160 },
  {
    field: "actionEdit",
    headerName: "تعديل",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
      handleEdit(params)
      };

      return <Button onClick={onClick}> <ModeEditIcon /></Button>;
    },
  },
  //   {
  //     field: 'action2',
  //     headerName: 'Action',
  //     sortable: false,
  //     renderCell: (params) => {


  //       return  <Link to="/View">
  //       <Button>View</Button>
  //     </Link> ;
  //     },}
];


const handleEdit = params => {
  (async () => {
   
    const  oldname =params.row.username
    const name =params.row.name
    const phone_number =params.row.phone_number
    const birthday =params.row.birthday
    const role  =params.row.role
    const percinterge =params.row.percinterge 
    if(role=='doctor'){
        const { value: formValues } = await Swal.fire({
         title: "معلومات المستخدم",
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> نسبه</label><br/><input id="percinterge" class="Text-end" style="width:90%;padding:5px" value="'+percinterge+'" >  </div>' +
         
          '<div style="width:100%"><label class="down"> الاسم</label><br/><input id="name" class="Text-end" style="width:90%;padding:5px" value="'+name+'" >  </div>' +
         
          "</div>" +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> رقم الهاتف</label><br/><input id="phone_number" class="Text-end" style="width:90%;padding:5px" value="'+phone_number+'" >  </div>' +
          '<div style="width:100%"><label class="down"> تاريخ الميلاد </label><br/><input id="birthday" type="date"class="Text-end" style="width:90%;padding:5px" value="'+birthday+'">  </div>' +
          "</div>" +
          "</div>" +
          "</div>",
        width: 1000,
      
        preConfirm: () => {
          
          return [
            document.getElementById("name").value,
            document.getElementById("phone_number").value,
            document.getElementById("birthday").value,
          update( document.getElementById("name").value,  document.getElementById("phone_number").value,document.getElementById("birthday").value,oldname,role,  document.getElementById("percinterge").value)
           
          ];
          
        },
     
        
    },
  
    
    );}
    if(role!='doctor'){
      const { value: formValues } = await Swal.fire({
       title: "معلومات المستخدم",
      html:
        '<div class="block" style="direction:ltr;">' +
        '<div class="Box" style="justify-content: space-around;">' +
       
       
        '<div style="width:100%"><label class="down"> الاسم</label><br/><input id="name" class="Text-end" style="width:90%;padding:5px" value="'+name+'" >  </div>' +
       
        "</div>" +
        '<div class="Box" style="justify-content: space-around;">' +
        '<div style="width:100%"><label class="down"> رقم الهاتف</label><br/><input id="phone_number" class="Text-end" style="width:90%;padding:5px" value="'+phone_number+'" >  </div>' +
        '<div style="width:100%"><label class="down"> تاريخ الميلاد </label><br/><input id="birthday" type="date"class="Text-end" style="width:90%;padding:5px" value="'+birthday+'">  </div>' +
        "</div>" +
        "</div>" +
        "</div>",
      width: 1000,
    
      preConfirm: () => {
        
        return [
          document.getElementById("name").value,
          document.getElementById("phone_number").value,
          document.getElementById("birthday").value,
        update( document.getElementById("name").value,  document.getElementById("phone_number").value,document.getElementById("birthday").value,oldname,role,0)
         
        ];
        
      },
   
      
  },

  
  );}
function update(name,phone_number,birthday,oldname,role,percinterge) {
const tokens = JSON.parse(localStorage.getItem('tokens'));

api.put(`/doctor?username=`+oldname , {
  role: role,
  name: name,
  phone_number :phone_number,
  birthday :birthday,
  percinterge: parseFloat(percinterge)
},
  { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
    if (error.response.data) {
      console.log(error.response.data.error)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response.data.error + '!',

      })
      return 0;

    }

  })
Swal.fire({
  icon: 'success',
  title: 'success...',
  text: 'User update',

}).then(() =>
window.location.reload()
);
}
  
   
  })()};


   //API object tableData for view data 
export default function DataTable() {
  const [TableData, setTableData] = useState([]);

  function Add() {
    (async () => {
      var ismale = false
      const { value: formValues } = await Swal.fire({
        title: "معلومات المستخدم",
        showCancelButton: true,
       
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> الاسم </label><br/><input id="name" class="Text-end" style="width:90%;padding:5px" required>  </div>' +
          '<div style="width:100%"><label class="down">   الاسم مستخدم</label><br/><input id="username" class="Text-end" style="width:90%;padding:5px" required>  </div>' +
  
          "</div>" +
     
          '<div class="Box" style="justify-content: space-around;">' +
          '  <div style="width:100%"><label class="down">وظيفه</label><br/><select class="Text-end" style="height:35px;width:90%;padding:5px"  id="role"><option value="admin">admin</option><option value="doctor">Doctor</option><option value="reception">Reception</option></select></div> '+
          '<div style="width:100%"><label class="down">كلمة السر</label><br/><input id="password"   class="Text-end" style="width:90%;padding:5px" required>  </div>' +
          "</div>" +
  
    
        
          '<div class="Box" style="justify-content: space-around;width:100%">' +
          '<div style="width:100%"><label class="down">رقم الهاتف</label><br/><input id="phone_number"  class="Text-end" style="width:90%;padding:5px" required>  </div>' +
          '<div style="width:100%"><label class="down">تاريخ الميلاد</label><br/><input id="birthday" class="Text-end" type="date" style="width:90%;padding:5px" required>  </div>' +
  
          "</div>" +
          '<div class="Box" style="justify-content: space-around;width:100%">' +
          '<div style="width:100%"><label class="down"> النسبه</label><br/><input id="percinterge" type="number" value=0  class="Text-end" style="width:90%;padding:5px" required>  </div>' +
  
          "</div>" +
          "</div>" +
          "</div>" +
  
          "</div>",
        width: 1000,
  
        preConfirm: () => {

          return [
            document.getElementById("name").value,
            document.getElementById("username").value,
            document.getElementById("role").value,
            document.getElementById("birthday").value,
            document.getElementById("password").value,
            document.getElementById("phone_number").value,
            document.getElementById("percinterge").value,
         
            CreateUser(  document.getElementById("name").value,
            document.getElementById("username").value,
            document.getElementById("role").value,
            document.getElementById("birthday").value,
            document.getElementById("password").value,
            document.getElementById("phone_number").value,
            document.getElementById("percinterge").value,)
          ];
        },
      }
      );
    
    
    })()
  }
  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    api.get('/doctors', {
      headers: {
        'Authorization': `Bearer ${tokens["access_token"]}`
      }
    }).then((data) => data['data']).then((data) => {
      console.log(data)
      data.forEach(patient => {
        patient.ismale? patient.ismale ="ذكر":patient.ismale ="أنثي"
        patient.isinstanced?patient.isinstanced = "نعم":patient.isinstanced = "لا" 
        patient.birthday = new Date(  patient.birthday)
        var a = Math.floor(patient.birthday.getTime() ) 
    a =new  Date(a);
       console.log(a.toISOString().split('T')[0])
       patient.birthday =a.toISOString().split('T')[0]
      })
      setTableData(data)
 
    } )
    
  }, []);
     //get id
  const handleGetRowId = (e) => {
    return e.uuid
  }
  return (
    <div>
            <div className="flex" style={{ padding: "10px 20px", marginTop: "5px ", }}>
      
      <div className="">
      <AddCircleIcon className="flex" onClick={() => Add()} />
      </div>
      <div className="">
      <label className="down"  >
        
        {"إضافة "}
        
      </label>
  
      </div>
              
      </div>
 
  
      <div style={{ height: 770, width: '100%', background: 'white', direction: "rtl" }} className="table" >
      <DataGrid
          rows={TableData}
          columns={columns}
          pageSize={12}
          getRowId={handleGetRowId}
         
          rowSelection='single'
          rowsPerPageOptions={[6]}
          

          className="DataGrid"
        />
      </div>
    </div>

  );
}
