import React, { useState,useEffect } from 'react'
import './Home.css'
import Navbar from '../Component/Navbar/Navbar';
import { Salder1 } from '../Component/Salder1/Salder1';
import axios from 'axios';
import Table from '../Component/Table/Table';
import { Create } from '../Component/Create/Create';
import api from "../api/index";
const Home = () => {
  const [isAdding, setIsAdding] = useState(true);
 
 
  
  React.useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    api.get('/patients' ,{headers: { 'Authorization':`Bearer ${tokens["access_token"]}`, 'content-type': 'application/json'} })
    
      .then(results => results['data'])
      .then(data => {
      
        console.log( data.results)
      });
  }, []); // <-- Have to pass in [] here!

  return (
    <div className='Home'>

      <div className='flex' style={{backgroundColor: "#D8DBDE"}}>

        {!isAdding && (
          <div className='Table-center' style={{justifyContent: "none"}}>
            <Navbar />
            <Table/>
          </div>
        )}
        {isAdding && (
          <div className='Table-center'>
            <Navbar />
            <Create />
          </div>
        )}





        <Salder1 setIsAdding={setIsAdding} isAdding={isAdding} />

      </div>




    </div>
  )
}

export default Home