import React,  { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Active,DisActive } from "../Models/model"
import { DataGrid } from '@mui/x-data-grid';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import './Table.css';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Swal from "sweetalert2";
import api from "../api/index";



export default function RoomsList() {

  function Add() {
    (async () => {
         
       
      const { value: formValues } = await Swal.fire({
        showCancelButton: true,
        title: "معلومات الغرفه",
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> اسم الغرفه </label><br/><input  name="Room" id="Room"  placeholder="اسم الغرفة" class="Text-end" style="width:94%;padding:5px" required>  </div>' +

          "</div>" +
          "</div>" +

          "</div>",
        width: 1000,

        preConfirm: () => {
        
         
          return [
            document.getElementById("Room").value,
          
            
          ];
        },
      }
      );

      if (formValues) {
        const json = Swal.fire(JSON.stringify(formValues));

   
console.log(formValues)
        handleSubmi(formValues)
      }
    })();
  }

  const [tableData, setTableData] = useState([]);
  



function handleSubmi(formValues) {
  
    const tokens = JSON.parse(localStorage.getItem('tokens'));

    api.post(`/room`, {
      code: formValues[0],

    },
      { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
        if (error.response.data) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response + '!',

          })
          return 0;

        }

      })
    Swal.fire({
      icon: 'success',
      title: 'success...',
      text: 'Room add it',

    }).then(() =>
    window.location.reload()
  );
    
  }

  useEffect(() => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    api.get('/rooms', {
      headers: {
        'Authorization': `Bearer ${tokens["access_token"]}`
      }
    }).then((data) => data['data']).then((data) => setTableData(data));
  }, []);

  const handleGetRowId = (e) => {
    return e.uuid
  }

  const columns = [
    { field: 'code', headerName: 'الغرفة ', width: 160 },
    { field: 'status', headerName: 'الحالة ', width: 160 },
    {
      field: "actionEdit",
      headerName: "تعديل",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
    
          handleEdit(params)
        };

        return <Button onClick={onClick}><ModeEditIcon /></Button>;
      },
    },
    {
      field: "diasvtice",
      headerName: "تنشيط",
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          
          const tokens = JSON.parse(localStorage.getItem('tokens'));
          if(params.row.status=='Active'){
           
            Active(params,'room')
          }else{
            DisActive(params,'room')
          }
        };
  if(params.row.status=='Active'){
    return <Button onClick={onClick}> <PersonIcon  style={{ color: '#ea0075' }}  /></Button>
  }else{
    return <Button onClick={onClick}> <PersonOffIcon style={{ color: 'red' }}  /></Button>
  }},}
  ];
    // {
    //   field: "actionDelete",
    //   headerName: "حذف",
    //   sortable: false,
    //   renderCell: (params) => {
    //     const onClick = () => {
    //       // onSelectionChanged()
        
    //       handleDelete(params)
    //     };

    //     return <Button onClick={onClick} ><DeleteIcon /></Button>;
    //   },
    // },


  // const handleDelete = params => {
  //   Swal.fire({
  //     icon: 'warning',
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, cancel!',
  //   }).then(result => {
  //     console.log("selected row " + params["id"])
  //     if (result.value) {
  //       // const [Datas] = tableData.filter(tableData => tableData.id === params["id"])
  //       // axios.delete request
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Deleted!',
  //         text: `${tableData.firstName} ${tableData.lastName}'s data has been deleted.`,
  //         showConfirmButton: false,
  //         timer: 1500,
  //       });

     
  //     } else {
  //       console.log("no")
  //     }
  //   });
  //   console.log()
  // };
  const handleEdit = params => {
    (async () => {
     
      const code =params.row.code
      const status =params.row.status
  
      const { value: formValues } = await Swal.fire({
        showCancelButton: true,
        title: "معلومات غرفة",
        html:
          '<div class="block" style="direction:ltr;">' +
          '<div class="Box" style="justify-content: space-around;">' +
          '<div style="width:100%"><label class="down"> code</label><br/><input id="code" class="Text-end" style="width:90%;padding:5px" value="'+code+'" >  </div>' +
          "</div>" +
          "</div>" +
          "</div>",
        width: 1000,
      
        preConfirm: () => {
          
          return [
            document.getElementById("code").value,
            update(code, document.getElementById("code").value)
           
          ];
          
        },
      
        
      }
      );
function update(code,Newcode) {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  api.put(`/room?code=`+code , {
    code: Newcode,
  },
    { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
      if (error.response.data) {
        console.log(error.response.data.error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error + '!',

        })
        return 0;

      }

    })
  Swal.fire({
    icon: 'success',
    title: 'success...',
    text: 'Room update',

  }).then(() =>
  window.location.reload()
);
}
    
     
    })()
  };
  return (

    <div>
      <div className="flex" style={{ padding: "10px 20px", marginTop: "5px ", }}>
      
    <div className="">
    <AddCircleIcon className="flex" onClick={() => Add()} />
    </div>
    <div className="">
    <label className="down"  >
      
      {"إضافة "}
      
    </label>

    </div>
            
      
       

      </div>
      <div style={{ height: 770, width: '100%', background: 'white', direction: "rtl" }} className="table" >
        <DataGrid
          rows={tableData}
          columns={columns}
          pageSize={12}
          getRowId={handleGetRowId}
         
          rowSelection='single'
          rowsPerPageOptions={[6]}
          

          className="DataGrid"
        />
      </div>
    </div>

  );
}
