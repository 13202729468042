import Swal from "sweetalert2";
import React, { useState,useEffect } from "react";
import api from "../api";

export function CreatePatient(formValues) {

    const tokens = JSON.parse(localStorage.getItem('tokens'));
    // console.log(`the value is ${Boolean(formValues[4])}`)
    api.post(`/patient` , {
      name:  formValues[0],
      address :formValues[1],
      birthday : formValues[2],
      ismale : (formValues[4] === 'true') ,
      phone_number :formValues[3],
    },
      { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
        if (error.response.data) {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.error + '!',
  
          })
          return 0;
  
        }
  
      })
    Swal.fire({
      icon: 'success',
      title: 'success...',
      text: 'Patient Create',
  
   }).then(() =>
   window.location.reload()
   );
  }
  export function CreateUser(name,username,role,birthday,password,phone_number,percinterge) {

  const tokens = JSON.parse(localStorage.getItem('tokens'));
  // console.log(`the value is ${Boolean(formValues[4])}`)
  api.post(`/doctor` , {
    name: name,
    username :username,
    role : role,
    birthday :birthday,
    password : password,
    phone_number : phone_number,
    percinterge: parseFloat(percinterge)
  },
    { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
      if (error.response.data) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.error + '!',

        })
        return 0;

      }

    })
  Swal.fire({
    icon: 'success',
    title: 'success...',
    text: 'Patient Create',

 }).then(() =>
 window.location.reload()
);
  }
  export function Active(params,type) {

    const tokens = JSON.parse(localStorage.getItem('tokens'));
    // console.log(`the value is ${Boolean(formValues[4])}`)
    api.put(`/`+ type +`/deactivate?uuid=`+params.row.uuid , {
      status : 'Avtice'
    },
      { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
        if (error.response.data) {
          console.log(error.response.data.error.status)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.error.status + '!',
  
          })
          return 0;
  
        }
  
      })
    Swal.fire({
      icon: 'success',
      title: 'success...',
      text:  type +' Avtice',
  
    }).then(() =>
    window.location.reload()
    );
  }       
   export function DisActive(params,type) {

    const tokens = JSON.parse(localStorage.getItem('tokens'));
    // console.log(`the value is ${Boolean(formValues[4])}`)
    api.put(`/`+ type +`/activate?uuid=`+params.row.uuid , {
      status : '"Inactive"'
    },
      { headers: { 'content-type': 'application/json', 'Authorization': `Bearer ${tokens["access_token"]}` }, }).catch(function (error) {
        if (error.response.data) {
          console.log(error.response.data.error.status)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error.response.data.error.status + '!',
  
          })
          return 0;
  
        }
  
      })
    Swal.fire({
      icon: 'success',
      title: 'success...',
      text: type + ' Inactive',
  
    }) .then(() =>
    window.location.reload()
    );
  } 